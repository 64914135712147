// 环境模式（production：生产环境，development：测试）
const ENV = process.env.NODE_ENV;

const REMOTE_SERVER = '/server';

const LOCAL_SERVER = [
    '/serv', // 测试服务器
    '/jsd', // 江树典
    '/online' //线上服务器
];

const API = {
    SERVER: ENV === 'production' ? REMOTE_SERVER : LOCAL_SERVER[1],
};

export default API
