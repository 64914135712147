import http from '../axios/http'

const global = {
    getPlantGrowSeedlingsListPage(params) {
        return http.get("/plantGrowSeedlings/getPlantGrowSeedlingsListPage", {params})
    },
    deletePlantGrowSeedlings(params) {
        return http.get("/plantGrowSeedlings/deletePlantGrowSeedlings", {params})
    },
    createdPlantGrowSeedlings(data) {
        return http.post("/plantGrowSeedlings/createdPlantGrowSeedlings", data)
    },
    updatePlantGrowSeedlings(data) {
        return http.post("/plantGrowSeedlings/updatePlantGrowSeedlings", data)
    },
    getPlantGrowSeedlingsList() {
        return http.get("/plantGrowSeedlings/getPlantGrowSeedlingsList")
    },

};

export default global
