import http from '../axios/http'

const global = {
    getDtuModelInstallInfoList(params) {
        return http.get("/device/getDtuModelInstallInfoList", {params})
    },
    deleteDtuModelInstallInfo(params) {
        return http.get("/device/deleteDtuModelInstallInfo", {params})
    },
    getMeterModelList(params) {
        return http.get("/device/getMeterModelList", {params})
    },
    getWindDirectionMeterIds(params) {
        return http.get("/device/getWindDirectionMeterIds", {params})
    },
    getAllMeterModelByOrganizationId(params) {
        return http.get("/device/getAllMeterModelByOrganizationId", {params})
    },
    createDtuModelInstallInfo(data) {
        return http.post("/device/createDtuModelInstallInfo", data)
    },
    updateDtuModelInstallInfo(data) {
        return http.post("/device/updateDtuModelInstallInfo", data)
    },
    getMeasurePointList(params) {
        return http.get("/device/getMeasurePointList", {params})
    },
    getLiquidLevelMeasurePointList(params) {
        return http.get("/device/getLiquidLevelMeasurePointList", {params})
    },
    deleteMeasurePoint(params) {
        return http.get("/device/deleteMeasurePoint", {params})
    },
    createMeasurePoint(data) {
        return http.post("/device/createMeasurePoint", data)
    },
    updateMeasurePoint(data) {
        return http.post("/device/updateMeasurePoint", data)
    },
};

export default global
