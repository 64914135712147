<template>
  <spin-loading v-if="isLoading" />
  <a-layout v-else id="components-layout-demo-custom-trigger">
    <a-layout-sider
      v-model="collapsed"
      :trigger="null"
      collapsible
      class="nav-main"
    >
      <!-- <div class="top_logo_title">
        <img :src="webSetting.menuLogoAddress" style="height: 100%;min-width: 30px"/>
        <div>
          <span v-if="!collapsed" style="color: #e9e9e9;font-weight: 400;font-size: 20px;margin-left: 7px;">
            {{webSetting.menuTitle}}
          </span>
        </div>
      </div> -->
      <!-- newHead -->
      <div class="nav-head">
        <div class="nav-info">
          <div class="nh-img-box">
            <img :src="webSetting.menuLogoAddress" />
          </div>
          <div class="nh-title">{{ webSetting.menuTitle }}</div>
        </div>
      </div>
      <a-menu
        mode="inline"
        theme="dark"
        :inline-collapsed="collapsed"
        :openKeys="openKeys"
        @openChange="onOpenChange"
        :selectedKeys="selectedKeys"
        @click="selectMenuItem"
      >
        <template v-for="item in menuList">
          <a-menu-item
            v-if="!item.children"
            :key="item.id"
          >
            <router-link :to="item.router == null ? '' : item.router">
              <a-icon v-if="item.icon" :type="item.icon" />
              <span>{{ item.title }}</span>
            </router-link>
          </a-menu-item>
          <sub-menu v-else :key="item.id" :menu-info="item" />
        </template>
      </a-menu>
    </a-layout-sider>

    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <div>
          <a-icon
            class="trigger"
            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            @click="() => (collapsed = !collapsed)"
          />
          <span  v-auth="'topMsg-select'">
            当前机构:
            {{selectOrganizationName == null || selectOrganizationName == "" ? user.organizationName ? user.organizationName : "" : selectOrganizationName}}
          </span>
        </div>
        <div>
          <template>
            <a-select
              style="width: 25rem"
              placeholder="请选择机构"
              @change="globalOrganizationChange"
              v-model="selectOrganizationObj"
              :labelInValue="true"
              v-auth="'topMsg-select'"
            >
              <a-select-option
                v-for="item in topOrganizationList"
                :key="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </template>
        </div>
        <div>
          <router-link :to="'dataV'">
            <el-button type="primary" size="small">全屏展示</el-button>
          </router-link>
          <a-dropdown style="margin-right: 1rem; margin-left: 1rem">
            <a @click="(e) => e.preventDefault()" style="color: #525457">
              <a-avatar
                v-if="
                  user.imgPathAll
                    ? false
                    : true || user.imgPathAll === null || user.imgPathAll === ''
                "
                icon="user"
              />
              <a-avatar v-else :src="user.imgPathAll" />
              <span
                style="margin-left: 8px; margin-right: 1px; font-size: 17px"
                >{{ this.$store.state.userInfo.username }}</span
              >
              <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item v-auth="'topMsg-look'">
                <router-link :to="'systemSetting'">
                  <span>网站设置</span>
                </router-link>
              </a-menu-item>
              <a-menu-item>
                <router-link :to="'userMsg'">
                  <span>个人信息</span>
                </router-link>
              </a-menu-item>
              <a-menu-item @click="loginOut">
                <span href="javascript:;">退出登录</span>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </a-layout-header>

      <a-layout-content class="router-box">
        <div class="rb-breadcrumb" v-if="breadcrumbInfo.show">
          <el-breadcrumb style="margin: 0;" separator="/">
            <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{breadcrumbInfo.meta.ups}}</el-breadcrumb-item>
            <el-breadcrumb-item>{{breadcrumbInfo.meta.label}}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <router-view class="router-sty"></router-view>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import { Menu } from "ant-design-vue";
import SpinLoading from "@/components/common/Loading/Index";

const SubMenu = {
  template: `
          <a-sub-menu :key="menuInfo.id" v-bind="$props" v-on="$listeners">
    <span slot="title">
          <a-icon v-if="menuInfo.icon" :type="menuInfo.icon"/><span>{{ menuInfo.title }}</span>
        </span>
            <template v-for="item in menuInfo.children">
              <a-menu-item v-if="!item.children" :key="item.id">
                <router-link :to="item.router==null?'':item.router">
                  <a-icon v-if="item.icon" :type="item.icon"/>
                  <span>{{ item.title }}</span>
                </router-link>
              </a-menu-item>
              <sub-menu v-else :key="item.id" :menu-info="item"/>
            </template>
          </a-sub-menu>
        `,
  name: "SubMenu",
  // must add isSubMenu: true
  isSubMenu: true,
  props: {
    ...Menu.SubMenu.props,
    // Cannot overlap with properties within Menu.SubMenu.props
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
  },
};

export default {
  components: {
    "sub-menu": SubMenu,
    SpinLoading,
  },

  props: {
    showLoading: false,
  },

  data() {
    return {
      selectOrganizationObj: [],
      selectOrganizationName: "",
      // menu选中
      selectedKeys: [],
      // menu展开
      openKeys: [],
      topOrganizationList: [],
      webSetting: {
        menuLogoAddress: "",
        menuTitle: "",
      },
      user: {
        id: "",
        username: "",
        organizationId: "",
        organizationName: "",
        phone: "",
        sex: "",
        imgPathAll: "",
      },
      defaultOpenKeys: [],
      collapsed: false,
      showMenuTitle: false,
      isLoading: this.showLoading,
      menuList: [],
      menuSubList: [],
      list: [
        {
          key: "1",
          icon: "pie-chart",
          title: "Option 1",
        },
        {
          key: "2",
          icon: "pie-chart",
          title: "Navigation 2",
          children: [
            {
              key: "2.1",
              title: "Navigation 3",
              children: [{ key: "2.1.1", title: "Option 2.1.1" }],
            },
          ],
        },
      ],
      breadcrumbInfo: {
        matched: [],
        meta: {},
        show: false
      },
    };
  },
  watch: {
    collapsed: function (val) {
      setTimeout(() => {
        this.showMenuTitle = val;
      }, 500);
    },
    $route:{
      handler(val,oldval){
        this.breadcrumbInfo = {
          matched: val.matched,
          meta: val.meta,
          show: val.meta.showBra || false
        }
      },
      deep: true
    }
  },
  mounted() {
    this.initWebSetting();
    this.initOrganizationMsg();
    this.initUserMsg();
    this.breadcrumbInfo = {
      matched: this.$route.matched,
      meta: this.$route.meta,
      show: this.$route.meta.showBra || false
    }
  },

  created() {
    this.getOnlyTopOrganizationList();
    this.getUserMenuList();
    this.getUserPageAuthorityList();
  },
  computed: {},
  methods: {
    initOrganizationMsg() {
      let organizationId = window.localStorage.getItem("organizationId");
      let organizationName = window.localStorage.getItem("organizationName");
      if (organizationName != null && organizationName != "") {
        this.selectOrganizationName = organizationName;
        this.selectOrganizationObj = {
          key: organizationId,
          label: organizationName,
        };
      }
    },
    globalOrganizationChange(value) {
      this.$message.success("请稍等,机构切换中......");
      // console.log(this.selectOrganizationObj);
      // console.log(value.key);
      // console.log(value.label);
      window.localStorage.setItem("organizationId", value.key);
      window.localStorage.setItem("organizationName", value.label);
      location.reload();
    },
    getOnlyTopOrganizationList() {
      this.http.organization.getOnlyTopOrganizationList().then((res) => {
        if (res.data.status == 200) {
          this.topOrganizationList = res.data.data;
        } else {
            //this.$message.warning("数据获取失败");
        }
      }).catch((err)=>{
            console.log("err"+err);
        });
    },
    initWebSetting() {
      this.http.global.getWebSetting().then(() => {
        let web = JSON.parse(window.localStorage.getItem("web_setting"));
        if (web.menuLogoAddress) {
          this.webSetting.menuLogoAddress = web.menuLogoAddressAll;
        }
        if (web.menuTitle) {
          this.webSetting.menuTitle = web.menuTitle;
        }
      });
    },
    initUserMsg() {
      this.http.global.getUserMsg().then((response) => {
        if (response.data.status === 200) {
            let user = response.data.data;
            window.localStorage.setItem("user_msg", JSON.stringify(user));
            this.$store.commit('setUserInfo',user)
            this.user = user;
        }
      });
    },
    getUserMenuList() {
      let that = this
      this.isLoading = true;
      this.http.pageAuth
        .getUserMenuList()
        .then((res) => {
          if (res.data.status == "200") {
            let list = res.data.data
            let slist = []
            list.forEach(val => {
              slist.push(val.id)
            });
            this.menuSubList = slist
            this.menuList = list;
            setTimeout(() => {
              this.isLoading = false;
            }, 1000);
          } else {
            // this.$message.error("获取数据超时");
          }
        })
        .then(()=>{
          let cur = this.$route.path
          let mlist = this.menuList
          let oid = [] //可存在多级 数组承载
          let sid = '' //单选菜单
          let menuPick = null
          for(let i=0; i<mlist.length; i++){
            // 无子菜单
            if(mlist[i].children == null){
              if(mlist[i].router == cur){
                oid = []
                sid = mlist[i].id
                break;
              }
            }else{
              menuPick = that.findMenuIndex(mlist[i].children,cur,[mlist[i].id])
              if(menuPick){
                oid = menuPick.parent
                sid = menuPick.child
                break;
              }
            }
          }
          this.openKeys = oid
          this.selectedKeys = [sid]
        })
        .catch((err) => {
          //this.$message.error(err);
          setTimeout(() => {
            this.isLoading = false;
          }, 30 * 1000);
          //   this.$message.error("获取数据超时");
          //console.log(err);
        });
    },
    getUserPageAuthorityList() {
      this.http.pageAuth
        .getPageAuthorityKeysByRoleId()
        .then((res) => {
          if (res.data.status === 200) {
            let pageAuthority = res.data.data;
            window.localStorage.setItem(
              "page_authority",
              JSON.stringify(pageAuthority)
            );
            setTimeout(() => {
              this.isLoading = false;
            }, 1500);
          }
        })
        .catch((error) => {
            console.log(error)
          //this.$message.error(res.data.msg);
          setTimeout(() => {
            this.isLoading = false;
          }, 30 * 1000);
        });
    },
    loginOut() {
      let that = this
      const tokenStr = window.localStorage.getItem("access_token");
      this.http.global.logout({ token: tokenStr }).then((res) => {
        if (res.data.status === 200) {
          localStorage.removeItem("user_msg");
          localStorage.removeItem("access_token");
          localStorage.removeItem("organizationId");
          localStorage.removeItem("organizationName");
          localStorage.removeItem("page_authority");
          setTimeout(() => {
            that.$router.replace("/login");
          }, 100);
          that.$message.success(res.data.msg);
        }
      });
    },
    selectMenuItem(item){
      if(this.selectedKeys[0] == item.key){
        return
      }else{
        this.selectedKeys = [item.key]
      }
    },
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
      if (this.menuSubList.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    findMenuIndex(item,cur,pidlist = []){
        for(let i=0; i<item.length; i++){
          if(item[i].children != null){
            pidlist.push(item[i].id)
            return this.findMenuIndex(item[i].children,cur,pidlist)
          }
          if(item[i].router == cur){
            let obj = {
              parent: pidlist,
              child: item[i].id
            }
            return obj
          }
        }
    }
  },
};
</script>
<style>
#components-layout-demo-custom-trigger .ant-layout-content .page-head-css {
  width: 100%;
  height: 3rem;
  background: #fff;
  margin-bottom: 1rem;
  z-index: 200;
}

#components-layout-demo-custom-trigger .ant-layout-content .page-body-css {
  margin: 20px;
  height: 88%;
  padding: 24px;
  background: #fff;
}

/* #components-layout-demo-custom-trigger .ant-layout-content .page-head-css div{
       display: table-cell;
       vertical-align: middle;
   }*/

#components-layout-demo-custom-trigger .ant-layout-content .page-css {
  height: 100%;
  padding: 24px;
  background: #fff;
}

.ant-layout-sider::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
}

.ant-layout-sider::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: #cecece;
}

.ant-layout-sider::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
}
#components-layout-demo-custom-trigger .ant-layout {
  overflow: auto !important;
}

#components-layout-demo-custom-trigger .ant-layout-sider {
  box-shadow: 0.05rem 0rem 0.3rem rgb(70% 70% 70%);
  overflow: auto;
}

#components-layout-demo-custom-trigger .ant-layout-header {
  height: 3rem;
  padding: 0 50px;
  line-height: 3rem;
  box-shadow: 0rem 1px 4px rgb(90% 90% 90%);
  z-index: 300;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#components-layout-demo-custom-trigger .ant-layout-content {
  height: 100%;
}

#components-layout-demo-custom-trigger {
  height: 100%;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 3rem;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.top_logo_title {
  height: 50px;
  display: flex;
  justify-content: center;
  padding: 10px;
}
</style>
<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.router-box{
  display: flex;
  flex-direction: column;
  .rb-breadcrumb{
    padding: 14px 14px 0 20px;
    display: flex;
    align-items: center;
    // .rb-item{
    //   margin-right: 14px;
    //   cursor: pointer;
    // }
  }
}
.router-sty {
  width: 100%;
  // height: 100%;
  flex: 1;
  overflow-y: auto;
}
.router-sty::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.router-sty::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: #1890ff;
  // background-image: -webkit-linear-gradient(
  //   45deg,
  //   rgba(255, 255, 255, 0.2) 25%,
  //   transparent 25%,
  //   transparent 50%,
  //   rgba(255, 255, 255, 0.2) 50%,
  //   rgba(255, 255, 255, 0.2) 75%,
  //   transparent 75%,
  //   transparent
  // );
}
.router-sty::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
  border-radius: 10px;
}
.nav-main {
  .nav-head {
    position: sticky;
    top: 0;
    left: 0;
    overflow: hidden;
    line-height: 64px;
    height: 64px;
    padding-left: 24px;
    transition: all 0.3s;
    background: #001529;
    z-index: 15;
    .nav-info {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      overflow: hidden;
      background: #001529;
      .nh-title {
        color: #fff;
        margin-left: 12px;
        font-size: 18px;
        font-weight: bold;
      }
      .nh-img-box {
        height: 64px;
        img {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}
</style>
