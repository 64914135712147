import http from '../axios/http'

const global = {

    getCameraToken() {
        return http.get("/camera/getCameraToken")
    },
    deleteCameraNetworkDvr(params) {
        return http.get("/camera/deleteCameraNetworkDvr", {params})
    },
    createCameraNetworkDvr(data) {
        return http.post("/camera/createCameraNetworkDvr", data)
    },
    updateCameraNetworkDvr(data) {
        return http.post("/camera/updateCameraNetworkDvr", data)
    },
    getCameraNetworkDvrList() {
        return http.get("/camera/getCameraNetworkDvrList")
    },

    deleteCameraDrive(params) {
        return http.get("/camera/deleteCameraDrive", {params})
    },
    createCameraDrive(data) {
        return http.post("/camera/createCameraDrive", data)
    },
    updateCameraDrive(data) {
        return http.post("/camera/updateCameraDrive", data)
    },
    getCameraDriveList(params) {
        return http.get("/camera/getCameraDriveList",{params})
    },
    getCameraDriveListPage(params) {
        return http.get("/camera/getCameraDriveListPage",{params})
    },

};

export default global
