import http from '../axios/http'

const global = {
    getPlantWayListPage(params) {
        return http.get("/plantWay/getPlantWayListPage", {params})
    },
    deletePlantWay(params) {
        return http.get("/plantWay/deletePlantWay", {params})
    },
    createdPlantWay(data) {
        return http.post("/plantWay/createdPlantWay", data)
    },
    updatePlantWay(data) {
        return http.post("/plantWay/updatePlantWay", data)
    },
    getPlantWayList() {
        return http.get("/plantWay/getPlantWayList")
    },

};

export default global
