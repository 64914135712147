<template>
  <spin-loading v-if="isLoading" />
  <div v-else id="userLayout" :class="['user-layout-wrapper']">
    <div class="container">
      <div class="login_model">
        <div>
          <div class="login_box">
            <div class="avatar_box_img">
              <img :src="webSetting.loginSrc" style="height: 3.5rem" />
            </div>
            <div class="avatar_box">
              账号密码登录
              <div class="ant-tabs-ink-bar"></div>
            </div>
            <a-form-model
              class="login_form"
              ref="loginRefForm"
              :model="loginForm"
              :rules="rules"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 15 }"
            >
              <a-form-model-item label="账号" prop="username">
                <a-input
                  type="text"
                  placeholder="请输入账号"
                  style="height: 35px"
                  v-model="loginForm.username"
                  allowClear
                ></a-input>
              </a-form-model-item>
              <a-form-model-item label="密码" prop="password">
                <a-input-password
                  placeholder="请输入密码"
                  style="height: 35px"
                  v-model="loginForm.password"
                  @pressEnter="toLogin"
                  allowClear
                ></a-input-password>
              </a-form-model-item>
              <div class="login_button">
                <a-button
                  style="
                    background: #4878bc;
                    border-color: #4878bc;
                    color: white;
                    height: 35px;
                    width: 4rem;
                  "
                  @click="toLogin"
                  >登录
                </a-button>
                <a-button
                  style="
                    background: #909399;
                    border-color: #909399;
                    color: white;
                    height: 35px;
                    margin-left: 0.3rem;
                    width: 4rem;
                  "
                  @click="toReset"
                >
                  重置
                </a-button>
              </div>
            </a-form-model>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="links">
          <a href="_self">帮助</a>
          <a href="_self">隐私</a>
          <a href="_self">条款</a>
        </div>
        <div class="copyright">
          Copyright &copy; 2021
          {{ webSetting.webCopyright }}

        </div>
        <a href="http://beian.miit.gov.cn">粤ICP备19100710号</a>
      </div>
    </div>
  </div>
</template>

<script>
import SpinLoading from "@/components/common/Loading/Index";
export default {
  name: "Login",
  components: {
    SpinLoading,
  },
  data() {
    return {
      isLoading: true,
      webSetting: {
        loginSrc: "",
        webCopyright: "",
        menuTitle: "",
      },
      loginForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [{ required: true, message: "请输入账号!", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码!", trigger: "blur" }],
      },
    };
  },
  mounted() {},
  created() {
    this.initWebSetting();
  },
  methods: {
    initWebSetting() {
      this.http.global.getWebSetting().then(() => {
        let web = JSON.parse(window.localStorage.getItem("web_setting"));
        if (web != null && web.loginLogoAddressAll) {
          this.webSetting.loginSrc = web.loginLogoAddressAll;
        }
        if (web != null && web.webCopyright) {
          this.webSetting.webCopyright = web.webCopyright;
        }
        if (web != null && web.menuTitle) {
          this.webSetting.menuTitle = web.menuTitle;
        }
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      });
    },
    toReset() {
      this.$refs.loginRefForm.resetFields();
    },
    toLogin() {
      let that = this;
      that.$refs.loginRefForm.validate((valid) => {
        if (valid) {
          that.http.global.login(that.loginForm).then((res) => {
            if (res.data.status === 200) {
              window.localStorage.setItem(
                "access_token",
                "Bearer" + res.data.data.access_token
              );
              that.$message.success(res.data.msg);
              that.$router.push("/home");
            } else {
              that.$message.warning(res.data.msg);
            }
          });
        } else {
          that.$message.warning("请输入账号或密码");
          return false;
        }
      });
    },
  },
  mounted() {
    document.body.classList.add("userLayout");
  },
  beforeDestroy() {
    document.body.classList.remove("userLayout");
  },
};
</script>

<style lang="less" scoped>
#userLayout.user-layout-wrapper {
  height: 100%;

  .container {
    width: 100%;
    min-height: 100%;
    background: #f0f2f5 url(~@/assets/background.svg) no-repeat 50%;
    background-size: 100%;
    padding: 110px 0 144px;
    position: relative;

    .footer {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 0 16px;
      margin: 48px 0 24px;
      text-align: center;

      .links {
        margin-bottom: 8px;
        font-size: 14px;

        a {
          color: rgba(0, 0, 0, 0.45);
          transition: all 0.3s;

          &:not(:last-child) {
            margin-right: 40px;
          }
        }
      }

      .copyright {
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
      }
    }
  }
}

.login_model {
  min-height: 370px;
}

.login_box {
  width: 500px;
  height: 300px;
  background-color: #fff;
  border-radius: 6px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.avatar_box_img {
  position: absolute;
  padding: 0rem;
  left: 50%;
  top: -30%;
  transform: translate(-50%, -10%);
}

/* .avatar_box_img img {
    width: 100%;
    height: 100%;
  }*/

.avatar_box {
  position: absolute;
  padding: 3rem;
  left: 50%;
  top: 0%;
  transform: translate(-50%, -10%);
}

.login_form {
  position: absolute;
  bottom: 13%;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.login_button {
  margin-top: 1.2rem;
  display: flex;
  justify-content: center;
}

.ant-tabs-ink-bar {
  bottom: 2.5rem;
  z-index: 1;
  width: 100px;
  height: 2px;
  left: 20%;
  background-color: #4878bc;
}
</style>
