<template>
  <div class="camera">
    <div class="video-title">
      <dv-decoration-7 style="width: 150px; height: 30px"
        >农场监控</dv-decoration-7
      >
    </div>
    <dv-loading class="loading-sty" v-if="loading"
      ><span class="loadingtxt">Loading...</span></dv-loading
    >
    <div class="mask"></div>
    <div class="video-list">
      <div class="video-item" id="video-container-1" ref="video1"></div>
      <div class="video-item" id="video-container-2" ref="video2"></div>
      <div
        class="video-item"
        v-if="hasMany"
        id="video-container-3"
        ref="video3"
      ></div>
      <div
        class="video-item"
        v-if="hasMany"
        id="video-container-4"
        ref="video4"
      ></div>
    </div>
  </div>
</template>

<script>
import EZUIKit from "ezuikit-js";
export default {
  data() {
    return {
      loading: true,
      curentData: {},
      token: "",
      player: [{}],
      hasMany: false,
    };
  },
  props: {
    colorlsit: [],
  },
  mounted() {
    this.getData();
  },
  methods: {
    // 获取图表数据
    getData() {
      // 加载
      this.loading = true;
      this.http.dataV
        .getCameraDriveList()
        .then((res) => {
          if (res.status == "200") {
            let data = res.data.data || [];
            if (data.length > 2) {
              this.hasMany = true;
            }
            // // console.log("获取摄像头列表", data);
            // this.loading = false;
            this.curentData = data;
          } else {
            this.$message.error("获取设备监控数据超时");
          }
        })
        .then(() => {
          this.getCameraToken();
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    getCameraToken() {
      let that = this;
      this.http.camera
        .getCameraToken()
        .then((res) => {
          if (res.status == "200") {
            that.token = res.data.data.accessToken;
            that.initCamera();
          } else {
            that.$message.error("网络繁忙，请稍后再试。");
          }
        })
        .catch((err) => {
          this.$message.error("网络繁忙，请稍后再试。");
          // console.log(err);
        });
    },
    initCamera() {
      let that = this;
      let clist = that.curentData;
      this.$nextTick(() => {
        clist.forEach((val, index) => {
          // // console.log('视频容器高度',that.$refs['video' + (index + 1)].scrollHeight)

          let viewHeight = that.$refs["video" + (index + 1)].scrollHeight || "";
          let viewWidth = that.$refs["video" + (index + 1)].scrollWidth || "";
          let vHeight = "",
            vWidth = "";
          // 判断容器高度比
          if (viewWidth >= parseInt(viewHeight * 1.78)) {
            vHeight = viewHeight;
            vWidth = parseInt(viewHeight * 1.78);
          } else {
            vHeight = parseInt(viewWidth * 0.56);
            vWidth = viewWidth;
            that.$refs["video" + (index + 1)].style.background = "#000000";
          }

          that.player[index] = new EZUIKit.EZUIKitPlayer({
            autoplay: true,
            id: `video-container-${index + 1}`,
            accessToken: that.token,
            url:
              "ezopen://DIANAY@open.ys7.com/" +
              val.cameraNetworkDvrSerialNumber +
              "/" +
              val.informationId +
              ".live",
            template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
            audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
            width: vWidth,
            height: vHeight,
            fullScreenCallBack: data => console.log("全屏回调", data),
          });
        });
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped lang="less">
* {
  box-sizing: border-box;
}
.loading-sty{
  position: absolute;
}
.camera {
  width: 100%;
  height: 100%;
  position: relative;
  .mask{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
  }
  .video-title {
    position: absolute;
    color: #ffffff;
    top: -10px;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
  }
  .video-list {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 8px;
    .video-item {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.1);
    }
    .video-item:not(:last-child) {
      margin-right: 4px;
    }
  }
}
.loadingtxt {
  color: #ffffff;
}
</style>


