import http from '../axios/http'

const global = {
    getFarmDeviceDetail(params) {
        return http.get("/datav/getFarmDeviceDetail", {params})
    },
    getSoilDetail(params) {
        return http.get("/datav/getSoilDetail", {params})
    },
    getFarmAgriculturalProductsMsg(params) {
        return http.get("/datav/getFarmAgriculturalProductsMsg", {params})
    },
    getFarmAgriculturalGrowthCycle(params) {
        return http.get("/datav/getFarmAgriculturalGrowthCycle", {params})
    },
    getFarmEnvironmentMsg(params) {
        return http.get("/datav/getFarmEnvironmentMsg", {params})
    },
    getWarningMsg(params) {
        return http.get("/datav/getWarningMsg", {params})
    },
    getFarmMachineryList(params) {
        return http.get("/datav/getFarmMachineryList", {params})
    },
    getFarmStatisticsData(params) {
        return http.get("/datav/getFarmStatisticsData", {params})
    },
    getCameraDriveList(params) {
        return http.get("/datav/getCameraDriveList", {params})
    },
    getTopPortionInfo(params) {
        return http.get("/datav/getTopPortionInfo", {params})
    },
    getCropManagementList(params) {
        return http.get("/datav/getCropManagementList", {params})
    },
    getWeatherInfo(params) {
        return http.get("/datav/getWeatherInfo", {params})
    },
    getFarmParcelList(params) {
        return http.get("/datav/getFarmParcelList", {params})
    },
};

export default global