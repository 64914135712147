import http from '../axios/http'

const global = {
    getFarmMachineryList(params) {
        return http.get("/farmMachinery/getFarmMachineryList", {params})
    },
    getFarmMachineryListPage(params) {
        return http.get("/farmMachinery/getFarmMachineryListPage", {params})
    },
    createdFarmMachinery(data) {
        return http.post("/farmMachinery/createdFarmMachinery", data)
    },
    updateFarmMachinery(data) {
        return http.post("/farmMachinery/updateFarmMachinery", data)
    },
    deleteFarmMachinery(params) {
        return http.get("/farmMachinery/deleteFarmMachinery", {params})
    },
};

export default global
