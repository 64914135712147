import Vue from 'vue'
import moment from "moment";

Vue.filter('dateFormat', function (daraStr) {
    if (!daraStr) return '';
    return moment(daraStr).format('YYYY-MM-DD')
});
Vue.filter('dateFormatFull', function (daraStr) {
    if (!daraStr) return '';
    return moment(daraStr).format('YYYY-MM-DD HH:mm:ss')
});
