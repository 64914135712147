import http from '../axios/http'

const global = {
    getFarmParcelGroupByFarmAreaId(params) {
        return http.get("/farmParcel/getFarmParcelGroupByFarmAreaId", {params})
    },
    getFarmParcelList(params) {
        return http.get("/farmParcel/getFarmParcelList", {params})
    },
    createFarmParcel(data) {
        return http.post("/farmParcel/createFarmParcel", data)
    },
    updateFarmParcel(data) {
        return http.post("/farmParcel/updateFarmParcel", data)
    },
    deleteFarmParcel(params) {
        return http.get("/farmParcel/deleteFarmParcel", {params})
    },
};

export default global
