import http from '../axios/http'

const api = {
        getNewsList(params) {
            return http.get("/news/getNewsList", {params})
        },
        updateUserOrganizationMsg(data) {
            return http.post("/organization/updateUserOrganizationMsg", data)
        },
        deleteNews(id) {
            return http.get("/news/deleteNews", {params: {id: id}})
        },
    createNews(data) {
        return http.post("/news/createNews", data)
    },
    updateNews(data) {
        return http.post("/news/updateNews", data)
    }


    };

export default api
