<template>
  <div class="chart">
    <dv-loading v-if="loading" class="loding-sty"
      ><span class="loadingtxt">Loading...</span></dv-loading
    >
    <div id="soilChart" v-if="chartFlag"></div>
    <div class="title">{{ chartDataList[0].label || "" }}</div>
  </div>
</template>

<script>
// 引入图表g2模块
import { Chart } from "@antv/g2";
// "colors10":["#5B8FF9","#5AD8A6","#5D7092","#F6BD16","#6F5EF9","#6DC8EC","#945FB9","#FF9845","#1E9493","#FF99C3"]
// "colors20":["#5B8FF9","#CDDDFD","#5AD8A6","#CDF3E4","#5D7092","#CED4DE","#F6BD16","#FCEBB9","#6F5EF9","#D3CEFD","#6DC8EC","#D3EEF9","#945FB9","#DECFEA","#FF9845","#FFE0C7","#1E9493","#BBDEDE","#FF99C3","#FFE0ED"]
export default {
  data() {
    return {
      loading: true,
      parcelList: [],
      chart: null,
      chartData: [],
      chartDataIndex: 0,
      chartDataList: [{ label: "" }],
      // 图表颜色列表
      colorList: [
        "#9966ff",
        "#6666ff",
        "#6699ff",
        "#66ff99",
        "#ffff66",
        "#ffcc66",
        "#ff9966",
        "#ff6666",
        "#abcdef",
        "#bedcaf",
        "#cafedb",
        "#decafb",
      ],
      chartFlag: true,
    };
  },
  props: {
    colorlsit: [],
  },
  mounted() {
    this.getFarmParcelList();
  },
  methods: {
    getFarmParcelList() {
      let that = this;
      that.http.dataV.getFarmParcelList().then((res) => {
        if (res.data.status == 200) {
          let list = res.data.data;
          that.parcelList = list;
            that.getData();
          setInterval(() => {
            that.getData();
          }, 30 * 60 * 1000);
        } else {
          that.$message.warning("数据获取失败");
        }
      });
    },
    // 获取图表数据
    getData() {
      // 加载
      let that = this
      if (that.chart == null) {
        that.loading = true;
      }
      let data = {};
      data.farmParcelId = that.parcelList[that.chartDataIndex].id;
      that.http.dataV
        .getSoilDetail(data)
        .then((res) => {
          if (res.status == "200") {
            let data = res.data.data;
            let clist = [];
            for (let key in data) {
              clist.push({
                label: key,
                value: data[key],
              });
            }
            that.chartDataList = clist;
            if (that.chart == null) {
              that.initChart();
            }else{
                if(clist[0].value.length>0){
                    that.chart.data(clist[0].value);
                    that.chart.render();
                }
            }
            that.chartDataIndex + 1 < that.parcelList.length
              ? (that.chartDataIndex = that.chartDataIndex + 1)
              : (that.chartDataIndex = 0);
          } else {
            that.$message.error("获取设备监控数据超时");
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    uploadChart() {
      // this.chartFlag = false
      // setTimeout(()=>{
      //   this.chartFlag = true
      // this.$nextTick(()=>{
      // this.initChart();
      // })
      // },500)
      let that = this;
      that.chart.data(that.chartDataList[that.chartDataIndex].value);
      that.chart.render();
      that.chartDataIndex + 1 < that.chartDataList.length
        ? (that.chartDataIndex = that.chartDataIndex + 1)
        : (that.chartDataIndex = 0);
    },
    initChart() {
      let that = this;
      that.loading = true;
      that.chart = new Chart({
        container: "soilChart",
        autoFit: true,
      });
      that.chart.data(that.chartDataList[0].value);
      // console.log(that.chartDataList[that.chartDataIndex].value)
      // that.chartDataIndex + 1 < that.chartDataList.length
      //   ? (that.chartDataIndex = that.chartDataIndex + 1)
      //   : (that.chartDataIndex = 0);
      that.chart.scale({
        collectItem: {
          range: [0, 1],
        },
        value: {
          nice: true,
          type: "pow",
        },
      });
      that.chart.axis("time", {
        title: null,
        grid: null,
        label: {
          style: {
            fill: "#ffffff",
          },
        },
        style: {
          fill: "#ffffff",
        },
      });
      that.chart.axis("value", {
        title: null,
        grid: null,
        label: {
          style: {
            fill: "#ffffff",
          },
        },
        style: {
          fill: "#ffffff",
        },
      });
      that.chart
        .area()
        // .adjust("stack")
        .position("time*value")
        .color("collectItem", that.colorList)
        .style({
          fillOpacity: 0.3,
        });

      that.chart.tooltip({
        showCrosshairs: true, // 展示 Tooltip 辅助线
        shared: true,
      });
      that.chart
        .line()
        .position("time*value")
        .color("collectItem", that.colorList)
        .shape("smooth");
      that.chart.legend(false); // 关闭图例
      that.$nextTick(() => {
        that.chart.render();
        that.loading = false;
      });
    },
  },
};
</script>

<style scoped lang="less">
* {
  box-sizing: border-box;
}
#soilChart {
  height: 100%;
}
.chart {
  width: 100%;
  height: calc(100% - 50px);
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  position: relative;
}
.loding-sty {
  position: absolute;
  top: 0;
  left: 0;
}
.title {
  position: absolute;
  top: -14px;
  color: #fff;
  left: 50%;
}
.loadingtxt {
  color: #ffffff;
}
</style>


