import http from '../axios/http'

const global = {
    getPlantYieldPredictTableDataByYear(params) {
        return http.get("/plantYieldPredict/getPlantYieldPredictTableDataByYear", {params})
    },

    updatePlantYieldPredict(data) {
        return http.post("/plantYieldPredict/updatePlantYieldPredict", data)
    },
};

export default global
