import http from '../axios/http'

const global = {
    getFarmAreaList(params) {
        return http.get("/farmArea/getFarmAreaList", {params})
    },
    deleteFarmArea(params) {
        return http.get("/farmArea/deleteFarmArea", {params})
    },
    createFarmArea(data) {
        return http.post("/farmArea/createFarmArea", data)
    },
    updateFarmArea(data) {
        return http.post("/farmArea/updateFarmArea", data)
    },
};

export default global
