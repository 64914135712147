<template>
  <div class="chart">
    <dv-loading v-if="loading"
      ><span class="loadingtxt">Loading...</span></dv-loading
    >
    <div id="machineryChart"></div>
  </div>
</template>

<script>
// 引入图表g2模块
import { Chart } from "@antv/g2";
import DataSet from "@antv/data-set";
// "colors10":["#5B8FF9","#5AD8A6","#5D7092","#F6BD16","#6F5EF9","#6DC8EC","#945FB9","#FF9845","#1E9493","#FF99C3"]
// "colors20":["#5B8FF9","#CDDDFD","#5AD8A6","#CDF3E4","#5D7092","#CED4DE","#F6BD16","#FCEBB9","#6F5EF9","#D3CEFD","#6DC8EC","#D3EEF9","#945FB9","#DECFEA","#FF9845","#FFE0C7","#1E9493","#BBDEDE","#FF99C3","#FFE0ED"]
export default {
  data() {
    return {
      loading: true,
      chart: null,
      chartData: [],
      chartDataList: [],
      chartColor: [
        "#5B8FF9",
        "#5AD8A6",
        "#5D7092",
        "#F6BD16",
        "#6F5EF9",
        "#6DC8EC",
        "#945FB9",
        "#FF9845",
        "#1E9493",
        "#FF99C3",
      ],
    };
  },
  props: {
    colorlsit: [],
  },
  mounted() {
    this.getData();
  },
  methods: {
    // 获取图表数据
    getData() {
      // 加载
      this.loading = true;
      this.http.dataV
        .getFarmMachineryList()
        .then((res) => {
          if (res.status == "200") {
            let data = res.data.data;
            this.chartData = data;
            // // console.log(data)
            this.initChart();
          } else {
            this.$message.error("获取设备监控数据超时");
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    initChart() {
      let that = this;
      that.loading = true;
      // let data = [
      //   { item: "Design", a: 70, b: 30 },
      //   { item: "Development", a: 60, b: 70 },
      //   { item: "Marketing", a: 50, b: 60 },
      //   { item: "Users", a: 40, b: 50 },
      //   { item: "Test", a: 60, b: 70 },
      //   { item: "Language", a: 70, b: 50 },
      //   { item: "Technology", a: 50, b: 40 },
      //   { item: "Support", a: 30, b: 40 },
      //   { item: "Sales", a: 60, b: 40 },
      //   { item: "UX", a: 50, b: 60 },
      // ];
      let data = that.chartData
      // // console.log(that.chartData)
      let { DataView } = DataSet;
      let dv = new DataView().source(data);
      dv.transform({
        type: "fold",
        fields: ["num"], // 展开字段集
        key: "user", // key字段
        value: "score", // value字段
      });

      that.chart = new Chart({
        container: "machineryChart",
        autoFit: true,
        height: 500,
      });
      that.chart.data(dv.rows);
      that.chart.scale("score", {
        min: 0,
        // max: 80,
        nice: true
      });
      that.chart.coordinate("polar", {
        radius: 0.8,
      });
      that.chart.tooltip({
        shared: true,
        showCrosshairs: true,
        crosshairs: {
          line: {
            style: {
              lineDash: [4, 4],
              stroke: "#ffffff",
            },
          },
        },
      });
      that.chart.axis("name", {
        line: null,
        tickLine: null,
        label:{
          style: {
            fill: '#ffffff'
          }
        },
        grid: {
          line: null,
          style: {
            fill: '#ffffff'
          }
        },
      });
      that.chart.axis("score", {
        line: null,
        tickLine: null,
        label:{
          style: {
            fill: '#ffffff'
          }
        },
        grid: {
          line: {
            type: "line",
            style: {
              lineDash: null,
              stroke: '#ffffff', // 网格线的颜色
            },
          },
        },
      });

      that.chart.line().position("name*score").color("#00fdff").size(2);
      that.chart
        .point()
        .position("name*score")
        .color("#00fdff")
        .shape("circle")
        .size(3)
        .style({
          stroke: "#fff",
          lineWidth: 1,
          fillOpacity: 1,
        });
      that.chart.area().position("name*score").color("#00fdff");
      that.chart.legend(false); // 关闭图例
      // 渲染图表
      that.$nextTick(() => {
        that.chart.render();
        that.loading = false;
      });
    },
  },
};
</script>

<style scoped lang="less">
* {
  box-sizing: border-box;
}
#machineryChart {
  height: 100%;
}
.chart {
  width: 100%;
  height: calc(100% - 50px);
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}
.loadingtxt {
  color: #ffffff;
}
</style>


