<template>
  <div class="chart">
    <dv-loading v-if="loading"
      ><span class="loadingtxt">Loading...</span></dv-loading
    >
    <div class="farmDevice" v-else>
      <div class="top">
        <dv-active-ring-chart
          :config="farmDeviceConfig"
          style="width: 50%; height: 100%"
        />
        <div class="farm-device-list">
          <vue-seamless-scroll
            :data="farmDeviceList"
            :class-option="defaultOption"
            class="scroll-box"
          >
            <div
              class="farm-device-item"
              v-for="(item, index) in farmDeviceList"
              :key="index"
              ref="roll"
            >
              <div class="label">
                <div class="circle" :style="{ background: item.color }"></div>
                <div class="roll" :ref="'roll' + index" :id="'roll' + index">
                  <div
                    class="roll-txt"
                    :ref="'rolltxt' + index"
                    :id="'rolltxt' + index"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <div class="info">
                <div class="i-li">
                  总数<span>{{ item.total }}</span>
                </div>
                <div class="i-li">
                  在线<span>{{ item.online }}</span>
                </div>
                <div class="i-li">
                  离线<span>{{ item.offline }}</span>
                </div>
              </div>
            </div>
          </vue-seamless-scroll>
        </div>
      </div>
      <div class="bottom">
        <div class="b-item">
          设备共 <span>{{ farmDeviceData.totalNum }}</span> 台
          <img src="../../img/test/device_icon.png" alt="">
        </div>
        <div class="b-item">
          <span>{{ farmDeviceData.onLineNum }}</span> 台在线
          <img src="../../img/test/on_icon.png" alt="">
        </div>
        <div class="b-item">
          <span>{{ farmDeviceData.offLineNum }}</span> 台离线
          <img src="../../img/test/off_icon.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 滚动模块
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  name: "farmDevice",
  components: {
    vueSeamlessScroll,
  },
  computed: {
    defaultOption() {
      return {
        step: 0.1, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  data() {
    return {
      loading: true,
      farmDeviceData: {},
      farmDeviceList: [],
      yellowBarTextStyle: [
        {
          paddingLeft: "305px",
        },
      ],
      // 图表颜色列表
      colorList: [
        "#9966ff",
        "#6666ff",
        "#6699ff",
        "#66ff99",
        "#ffff66",
        "#ffcc66",
        "#ff9966",
        "#ff6666",
        "#abcdef",
        "#bedcaf",
        "#cafedb",
        "#decafb",
      ],
      farmDeviceConfig: {
        radius: "70%",
        activeRadius: "80%",
        color: [],
        data: [],
        digitalFlopStyle: {
          fontSize: 14,
        },
        showOriginValue: true,
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    // 获取图表数据
    getData() {
      // 加载
      this.loading = true;
      this.http.dataV
        .getFarmDeviceDetail()
        .then((res) => {
          if (res.data.status == "200") {
            let data = res.data.data;
            this.farmDeviceData = data;
            this.farmDeviceList = data.sensorDetail;
            // // console.log(data);
          } else {
            this.$message.error("获取设备监控数据超时");
          }
        })
        .then(() => {
          this.initFarmDeviceChart();
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    initFarmDeviceChart() {
      // 初始化图表数据
      let data = JSON.parse(JSON.stringify(this.farmDeviceData));
      let colorList = JSON.parse(JSON.stringify(this.colorList));
      let dlist = data.sensorCharDetail;
      let clist = [];
      dlist.forEach((val) => {
        clist.push({
          name: val.text,
          value: val.num,
        });
      });
      this.farmDeviceConfig.digitalFlopUnit = "台";
      this.farmDeviceConfig.data = clist;
      this.farmDeviceConfig.color = colorList;
      this.loading = false;
      // 初始化图例数据
      let llist = data.sensorDetail || [];
      let cindex = 0;
      let plist = [];
      for (let i = 0; i < llist.length; i++) {
        llist[i].color = colorList[cindex];
        plist.push({
          paddingLeft: "305px",
        });
        if (cindex < 10) {
          cindex++;
        } else {
          cindex = 0;
        }
      }
      this.yellowBarTextStyle = plist;
      this.farmDeviceList = llist;
      // // 渲染字节点后获取dom
      // this.$nextTick(() => {
      //   this._scroll();
      // });
    },
    _scroll() {
      let that = this;
      let list = this.farmDeviceList;
      list.forEach((val, index) => {
        let parent = document.getElementById("roll" + index);
        let child = document.getElementById("rolltxt" + index);
        //动态赋值动画区域的左padding 防止卡顿
        let parentClientWidth = parent.clientWidth;
        // that.yellowBarTextStyle[index].paddingLeft = parentClientWidth + "px";
        // //判断动画区域是否超出父元素宽度 宽则有动画，不宽则无
        // if (child.clientWidth - parentClientWidth > parent.clientWidth) {
        //   that.yellowBarTextStyle[index].paddingLeft = 0 + "px";
        //   child.classList.add("state-text-overflow");
        // } else {
        that.yellowBarTextStyle[index].paddingLeft = 0 + "px";
        //   child.classList.remove("state-text-overflow");
        // }
      });
    },
  }
};
</script>

<style scoped lang="less">
* {
  box-sizing: border-box;
}
.flex-row-center {
  display: flex;
  align-items: center;
}
.loadingtxt {
  color: #ffffff;
}
.chart {
  width: 100%;
  height: calc(100% - 50px);
  padding: 0 20px 10px 10px;
  // background: yellow;
  .farmDevice {
    width: 100%;
    height: 100%;
    .top {
      display: flex;
      align-items: center;
      height: calc(100% - 40px);
    }
    .bottom {
      height: 40px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: #ffffff;
      position: relative;
      // padding-top: 12px;
      .b-item{
        display: flex;
        align-items: center;
        span{
          margin: 0 2px;
        }
        img{
          width: 16px;
          height: auto;
          margin-left: 4px;
        }
      }
    }
    .bottom::after{
      position: absolute;
      content: '';
      width: 80%;
      background: rgba(140, 234, 255, .3);
      height: 1px;
      top: 8px;
    }
  }
}
// 文字自动滚动
.roll {
  width: 100%;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  box-sizing: border-box;
  // padding: 0 15px;
  .roll-txt {
    white-space: nowrap;
    display: inline-block;
  }
}
.state-text-overflow {
  animation: move_left_right 12s linear 0s infinite;
}
@keyframes move_left_right {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.i-li:nth-child(1),
.b-item:nth-child(1) {
  span {
    color: #00fdff;
  }
}
.i-li:nth-child(2),
.b-item:nth-child(2) {
  span {
    color: #1afa29;
  }
}
.i-li:nth-child(3),
.b-item:nth-child(3) {
  span {
    color: #d4237a;
  }
}
.farm-device-list {
  display: flex;
  flex-direction: column;
  color: #fff;
  height: 100%;
  overflow: hidden;
  margin: 0 0 0 0;
  // justify-content: center;
  padding: 0 20px 0 0;
  width: 50%;
  .farm-device-item {
    margin-bottom: 14px;
    .label {
      .flex-row-center();
      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 7px;
      }
    }
    .info {
      .flex-row-center();
      .i-li {
        margin-right: 4px;
        white-space: nowrap;
        display: inline-block;
        span {
          margin: 0 2px;
        }
      }
      // #00fdff  #1afa29  #d4237a
    }
  }
}
</style>


