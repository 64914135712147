import http from '../axios/http'

const api = {
    getUserOrganizationMsg() {
        return http.get("/organization/getUserOrganizationMsg", {})
    },
    updateUserOrganizationMsg(data) {
        return http.post("/organization/updateUserOrganizationMsg", data)
    },
    getOnlyTopOrganizationList() {
        return http.get("/organization/getOnlyTopOrganizationList", {})
    },
    getOrganizationList(params) {
        return http.get("/organization/getOrganizationList", {
            params
        })
    },
    getOrganizationOneselfList(params) {
        return http.get("/organization/getOrganizationOneselfList", {
            params
        })
    },
    getOrganizationTreeByArea(params) {
        return http.get("/organization/getOrganizationTreeByArea", {
            params
        })
    },
    createOrganization(data) {
        return http.post("/organization/createOrganization", data)
    },
    deleteOrganization(data) {
        return http.post("/organization/deleteOrganization", data)
    },
    updateOrganization(data) {
        return http.post("/organization/updateOrganization", data)
    },
};

export default api