import http from '../axios/http'

const global = {
    getAssetWarehouseListPage(params) {
        return http.get("/assetWarehouse/getAssetWarehouseListPage", {params})
    },
    deleteAssetWarehouse(params) {
        return http.get("/assetWarehouse/deleteAssetWarehouse", {params})
    },
    createAssetWarehouse(data) {
        return http.post("/assetWarehouse/createAssetWarehouse", data)
    },
    updateAssetWarehouse(data) {
        return http.post("/assetWarehouse/updateAssetWarehouse", data)
    },
    getAssetWarehouseList() {
        return http.get("/assetWarehouse/getAssetWarehouseList")
    },
    addAssetWarehouseNum(data) {
        return http.post("/assetWarehouse/addAssetWarehouseNum", data)
    },
    subtractAssetWarehouseNum(data) {
        return http.post("/assetWarehouse/subtractAssetWarehouseNum", data)
    },
    getAssetWarehouseLogPageById(params) {
        return http.get("/assetWarehouseLog/getAssetWarehouseLogPageById", {params})
    },

};

export default global
