import http from '../axios/http'

const global = {
    getUserMenuList(params) {
        return http.get("/pageAuth/getUserMenuList", {params})
    },
    getPageAuthorityKeysByRoleId(params) {
        return http.get("/pageAuth/getPageAuthorityKeysByRoleId", {params})
    },
    updateMenuIdsByRoleId(data) {
        return http.post("/pageAuth/updateMenuIdsByRoleId", data)
    },
    updatePageAuthorityIdsByRoleId(data) {
        return http.post("/pageAuth/updatePageAuthorityIdsByRoleId", data)
    },
    updateMiniAuthorityIdsByRoleId(data) {
        return http.post("/pageAuth/updateMiniAuthorityIdsByRoleId", data)
    },
    getAllMenuList(params) {
        return http.get("/pageAuth/getAllMenuList", {params})
    },
    getAllPageAuthorityList(params) {
        return http.get("/pageAuth/getAllPageAuthorityList", {params})
    },  
    getMenuIdsByRoleId(params) {
        return http.get("/pageAuth/getMenuIdsByRoleId", {params})
    },
    getPageAuthorityIdsByRoleId(params) {
        return http.get("/pageAuth/getPageAuthorityIdsByRoleId", {params})
    },
    getMiniAuthorityIdsByRoleId(params) {
        return http.get("/pageAuth/getMiniAuthorityIdsByRoleId", {params})
    },
    getAllMiniAuthorityList(params) {
        return http.get("/pageAuth/getAllMiniAuthorityList", {params})
    },
};

export default global
