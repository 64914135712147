import http from '../axios/http'

const global = {
    getPlantSeedlingsListPage(params) {
        return http.get("/plantSeedlings/getPlantSeedlingsListPage", {params})
    },
    deletePlantSeedlings(params) {
        return http.get("/plantSeedlings/deletePlantSeedlings", {params})
    },
    createdPlantSeedlings(data) {
        return http.post("/plantSeedlings/createdPlantSeedlings", data)
    },
    updatePlantSeedlings(data) {
        return http.post("/plantSeedlings/updatePlantSeedlings", data)
    },
    getPlantSeedlingsList() {
        return http.get("/plantSeedlings/getPlantSeedlingsList")
    },
    getPlantSeedlingsListByPlantVarietyId(params) {
        return http.get("/plantSeedlings/getPlantSeedlingsListByPlantVarietyId", {params})
    },

};

export default global
