<template>
  <div class="chart">
    <dv-loading v-if="loading"
      ><span class="loadingtxt">Loading...</span></dv-loading
    >
    <div id="insectChart"></div>
  </div>
</template>

<script>
// 引入图表g2模块
import { Chart } from "@antv/g2";
// "colors10":["#5B8FF9","#5AD8A6","#5D7092","#F6BD16","#6F5EF9","#6DC8EC","#945FB9","#FF9845","#1E9493","#FF99C3"]
// "colors20":["#5B8FF9","#CDDDFD","#5AD8A6","#CDF3E4","#5D7092","#CED4DE","#F6BD16","#FCEBB9","#6F5EF9","#D3CEFD","#6DC8EC","#D3EEF9","#945FB9","#DECFEA","#FF9845","#FFE0C7","#1E9493","#BBDEDE","#FF99C3","#FFE0ED"]
export default {
  data() {
    return {
      loading: true,
      insectChart: null,
      chartData: {},
      chartDataList: [],
      chartColor: [
        "#5B8FF9",
        "#5AD8A6",
        "#5D7092",
        "#F6BD16",
        "#6F5EF9",
        "#6DC8EC",
        "#945FB9",
        "#FF9845",
        "#1E9493",
        "#FF99C3",
      ],
    };
  },
  props: {
    colorlsit: [],
  },
  mounted() {
    this.getData();
  },
  methods: {
    // 获取图表数据
    getData() {
      // 加载
      this.loading = true;
      this.http.dataV
        .getCropManagementList()
        .then((res) => {
          if (res.status == "200") {
            let data = res.data.data;
            this.chartData = data
          this.initChart();
          } else {
            this.$message.error("获取设备监控数据超时");
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    initChart() {
      let that = this;
      that.loading = true;

      let data = JSON.parse(JSON.stringify(that.chartData));
      // console.log(data)
      let newdata = [];
      data.forEach(val=>{
        newdata.push({
          way: val.way,
          num: parseInt(val.num)
        })
      })
      that.insectChart = new Chart({
        container: "insectChart",
        autoFit: true,
        padding: [20, 20, 20, 50], // 上，右，下，左
      });
      that.insectChart.data(newdata);
      that.insectChart.scale("num", {
        alias: "数量",
        nice: true
      });
      that.insectChart.axis("num", {
        grid: null,
        label: {
          style: {
            fill: "#ffffff",
          },
        },
      });
      that.insectChart.axis("way", {
        tickLine: null,
        label: {
          style: {
            fill: "#ffffff",
          },
        },
      });
      that.insectChart.interaction("active-region");
      that.insectChart.tooltip({
        showMarkers: false,
      });
      // that.insectChart.interaction("active-region");
      // 添加文本标注
      data.forEach((item) => {
        that.insectChart
          .annotation()
          .text({
            position: [item.way, item.num],
            content: item.num,
            style: {
              textAlign: "center",
              fill: "#ffffff"
            },
            offsetY: -12,
          })
      });
      that.insectChart.interval().position("way*num");
      that.$nextTick(() => {
        that.insectChart.render();
        that.loading = false;
      });
    },
  },
};
</script>

<style scoped lang="less">
* {
  box-sizing: border-box;
}
#insectChart {
  height: 100%;
}
.chart {
  width: 100%;
  height: calc(100% - 50px);
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}
.loadingtxt {
  color: #ffffff;
}
</style>


