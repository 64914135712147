import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home'
import Login from '../components/Login'
import DataV from '../components/dataV/DataV'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: '/welcome'
    },
    {
        path: '/login',
        component: Login
    },
    {
        path: '/home',
        component: Home,
        redirect: '/welcome',
        children: [{
                path: '/welcome',
                name: 'welcome',
                component: () => import('@/components/Welcome')
            },
            {
                path: '/test',
                name: 'test',
                component: () => import('@/components/test/Test')
            },
            {
                path: '/realTimeData',
                name: 'realTimeData',
                component: () => import('@/components/FarmData/RealTimeData')
            },
            {
                path: '/organization',
                name: 'organization',
                component: () => import('@/components/system/Organization')
            },
            {
                path: '/organizationManagement',
                name: 'organizationManagement',
                meta: {
                    ups: '系统管理',
                    label: '组织架构',
                    showBra: true,
                },
                component: () => import('@/components/system/OrganizationManagement')
            },
            {
                path: '/userManagement',
                name: 'userManagement',
                meta: {
                    ups: '系统管理',
                    label: '用户管理',
                    showBra: true,
                },
                component: () => import('@/components/system/UserManagement')
            },
            {
                path: '/roleManagement',
                name: 'roleManagement',
                meta: {
                    ups: '系统管理',
                    label: '角色管理',
                    showBra: true,
                },
                component: () => import('@/components/system/RoleManagement')
            },
            {
                path: '/userMsg',
                name: 'userMsg',
                meta: {
                    ups: '系统管理',
                    label: '个人信息',
                    showBra: true,
                },
                component: () => import('@/components/system/UserMsg')
            },
            {
                path: '/farmArea',
                name: 'farmArea',
                meta: {
                    ups: '系统管理',
                    label: '基地管理',
                    showBra: true,
                },
                component: () => import('@/components/system/FarmArea')
            },
            {
                path: '/farmParcel',
                name: 'farmParcel',
                meta: {
                    ups: '系统管理',
                    label: '地块管理',
                    showBra: true,
                },
                component: () => import('@/components/system/farmParcel')
            },
            {
                path: '/dtuManagement',
                name: 'dtuManagement',
                meta: {
                    ups: '设备配置',
                    label: '网关设备管理',
                    showBra: true,
                },
                component: () => import('@/components/device/DtuManagement')
            },
            {
                path: '/measurePointManagement',
                name: 'measurePointManagement',
                meta: {
                    ups: '设备配置',
                    label: '采集设备管理',
                    showBra: true,
                },
                component: () => import('@/components/device/MeasurePointManagement')
            },
            {
                path: '/deviceManagement',
                name: 'deviceManagement',
                component: () => import('@/components/equipment/DeviceManagement')
            },
            {
                path: '/farmMachinery',
                name: 'farmMachinery',
                meta: {
                    ups: '设备管理',
                    label: '农机类型管理',
                    showBra: true,
                },
                component: () => import('@/components/equipment/farmMachinery')
            },
            {
                path: '/onlineMonitoring',
                name: 'onlineMonitoring',
                component: () => import('@/components/FarmData/OnlineMonitoring')
            },
            {
                path: '/warningMonitoring',
                name: 'warningMonitoring',
                component: () => import('@/components/FarmData/WarningMonitoring')
            },
            {
                path: '/websiteInfoManagement',
                name: 'websiteInfoManagement',
                component: () => import('@/components/system/WebsiteInfoManagement')
            },
            {
                path: '/systemSetting',
                name: 'systemSetting',
                meta: {
                    ups: '系统管理',
                    label: '系统设置',
                    showBra: true,
                },
                component: () => import('@/components/system/SystemSetting')
            },
            {
                path: '/agriculturalInformation',
                name: 'agriculturalInformation',
                meta: {
                    ups: '农识资讯',
                    label: '农识资讯',
                    showBra: true,
                },
                component: () => import('@/components/AgriculturalInfo/AgriculturalInformation'),
            },
            {
                path: '/agriculturalDetail',
                name: 'agriculturalDetail',
                component: () => import('@/components/AgriculturalInfo/AgriculturalDetail'),
            },
            {
                path: '/agriculturalPublish',
                name: 'agriculturalPublish',
                meta: {
                    ups: '农识资讯',
                    label: '农识资讯发布',
                    showBra: true,
                },
                component: () => import('@/components/AgriculturalInfo/AgriculturalPublish')
            },
            {
                path: '/addArticle',
                name: 'addArticle',
                component: () => import('@/components/AgriculturalInfo/AddArticle')
            },
            {
                path: '/editArticle',
                name: 'editArticle',
                component: () => import('@/components/AgriculturalInfo/EditArticle')
            },
            {
                path: '/companyInfo',
                name: 'companyInfo',
                meta: {
                    ups: '系统管理',
                    label: '企业信息',
                    showBra: true,
                },
                component: () => import('@/components/system/CompanyInfo')
            },
            {
                path: '/plantWay',
                name: 'plantWay',
                meta: {
                    ups: '农场管理',
                    label: '劳作方式',
                    showBra: true,
                },
                component: () => import('@/components/FarmManagement/PlantWay')
            },
            {
                path: '/plantVariety',
                name: 'plantVariety',
                meta: {
                    ups: '农场管理',
                    label: '品种管理',
                    showBra: true,
                },
                component: () => import('@/components/FarmManagement/PlantVariety')
            },
            {
                path: '/camera',
                name: 'camera',
                component: () => import('@/components/camera/Camera')
            },
            {
                path: '/cameraDvr',
                name: 'cameraDvr',
                meta: {
                    ups: '设备配置',
                    label: '网络录像机管理',
                    showBra: true,
                },
                component: () => import('@/components/device/CameraDvr')
            },
            {
                path: '/cameraDrive',
                name: 'cameraDrive',
                meta: {
                    ups: '设备配置',
                    label: '摄像头管理',
                    showBra: true,
                },
                component: () => import('@/components/device/CameraDrive')
            },
            {
                path: '/relayModel',
                name: 'relayModel',
                meta: {
                    ups: '设备配置',
                    label: '继电器管理',
                    showBra: true,
                },
                component: () => import('@/components/device/RelayModel')
            },
            {
                path: '/relayControlPoint',
                name: 'relayControlPoint',
                meta: {
                    ups: '设备配置',
                    label: '继电器控制点管理',
                    showBra: true,
                },
                component: () => import('@/components/device/RelayControlPoint')
            },
            {
                path: '/plantGrowSeedlings',
                name: 'plantGrowSeedlings',
                meta: {
                    ups: '农场管理',
                    label: '育苗批次',
                    showBra: true,
                },
                component: () => import('@/components/FarmManagement/PlantGrowSeedlings')
            },
            {
                path: '/plantSeedlings',
                name: 'plantSeedlings',
                meta: {
                    ups: '农场管理',
                    label: '种植批次',
                    showBra: true,
                },
                component: () => import('@/components/FarmManagement/PlantSeedlings')
            },
            {
                path: '/assetWarehouse',
                name: 'assetWarehouse',
                meta: {
                    ups: '农场管理',
                    label: '农资管理',
                    showBra: true,
                },
                component: () => import('@/components/FarmManagement/AssetWarehouse')
            },
            {
                path: '/plantYieldPredict',
                name: 'plantYieldPredict',
                meta: {
                    ups: '农场管理',
                    label: '产量预估',
                    showBra: true,
                },
                component: () => import('@/components/FarmManagement/PlantYieldPredict')
            },
            {
                path: '/cropManagement',
                name: 'cropManagement',
                meta: {
                    ups: '农场管理',
                    label: '农事任务',
                    showBra: true,
                },
                component: () => import('@/components/FarmManagement/CropManagement')
            },
            {
                path: '/sourceManagement',
                name: 'sourceManagement',
                component: () => import('@/components/source/SourceManagement')
            },
            {
                path: '/relayControlData',
                name: 'relayControlData',
                component: () => import('@/components/FarmData/RelayControlData')
            },
            {
                path: '/robotControlData',
                name: 'robotControlData',
                component: () => import('@/components/FarmData/RobotControlData.vue')
            },
            {
                path: '/aMapSelect',
                name: 'aMapSelect',
                component: () => import('@/components/map/AMapSelect.vue')
            },
            {
                path: '/report',
                name: 'report',
                component: () => import('@/components/report/report.vue')
            },
        ]
    },
    {
        path: '/dataV',
        component: DataV
    },
    {
        path: '/sourceMobile',
        component: () => import('@/components/mobile/SourceMobile')
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('access_token');
    const tokenStr = (token && token.split('Bearer')[1]) || ''
    if (to.path === '/login' || to.path === '/sourceMobile') {
        return next();
    }

    if (!tokenStr || tokenStr === '') {
        return next('/login');
    }

    next();
})

export default router
