import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//顶部进度条
import './permission/permission'

//引入全局请求
import qs from 'qs'
import * as http from './api';

//引入ant-design-vue
import antDesign from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';

//引入element
import './plugins/element.js'

//引入moment
import moment from 'moment'

//后台引入全局样式
import '@/css/components/global.css'

//引入权限全局指令和全局过滤器
import authVerify from './methods/methods'
import './directive/permission'
import  './filter/filter'

//引入axios前缀
import axios_env from './axios/base'

//引入高德地图组件

//import './map/amap'

//  屏幕适配方案
// import 'lib-flexible'

// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

// 注册全局nulldata组件
import nullData from '@/components/common/nullData.vue'
Vue.component('rb-empty',nullData)

//设置全局
Vue.prototype.qs = qs;
Vue.prototype.moment = moment;
Vue.prototype.http = http;
Vue.prototype.authVerify = authVerify;
Vue.prototype._axios_env = axios_env;
Vue.use(antDesign);


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
