<template>
  <div class="chart">
    <dv-loading v-if="loading"
      ><span class="loadingtxt">Loading...</span></dv-loading
    >
    <div class="warning" v-else>
      <dv-scroll-board
        :config="chartDataList"
        style="width: 100%; height: 220px"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "warningMsg",
  data() {
    return {
      loading: true,
      chart: null,
      chartData: [],
      chartDataList: [],
      // 图表颜色列表
      colorList: [
        "#9966ff",
        "#6666ff",
        "#6699ff",
        "#6699ff",
        "#6699ff",
        "#6699ff",
        "#6699ff",
        "#6699ff",
        "#6699ff",
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    // 获取图表数据
    getData() {
      // 加载
      this.loading = true;
      this.http.dataV
        .getWarningMsg()
        .then((res) => {
          if (res.status == "200") {
            let data = res.data.data;
            this.chartData = data;
            this.initChart();
          } else {
            this.$message.error("获取设备监控数据超时");
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    initChart() {
      let clist = this.chartData;
      let nlist = { header: ["地块", "区域", "内容", "预警时间"] };
      let list = []
      clist.forEach(val=>{
        //   // console.log(val)
          list.push([
            val.customMeterAddress,
            val.customMeterName,
            val.abnormalDescribe,
            val.time,
          ])
      })
      nlist.data = list
      nlist.waitTime = 4000
      nlist.headerBGC = '#3a7db8'
      nlist.oddRowBGC = '#054073'
      nlist.evenRowBGC = '#054073'
      this.chartDataList = nlist
      this.chartData = clist;
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="less">
* {
  box-sizing: border-box;
}
.loadingtxt {
  color: #ffffff;
}
.chart {
  width: 100%;
  height: calc(100% - 50px);
  padding: 0 20px 10px 20px;
  .warning {
    height: 100%;
    overflow: hidden;
  }
}
</style>


