import http from '../axios/http'

const global = {
    /*溯源产品*/
    deleteSourceManagement(params) {
        return http.get("/sourceManagement/deleteSourceManagement", {params})
    },
    createSourceManagement(data) {
        return http.post("/sourceManagement/createSourceManagement", data)
    },
    updateSourceManagement(data) {
        return http.post("/sourceManagement/updateSourceManagement", data)
    },
    getSourceManagementList() {
        return http.get("/sourceManagement/getSourceManagementList")
    },
    getSourceManagementById(params) {
        return http.get("/sourceManagement/getSourceManagementById",{params})
    },
    getSourceManagementBatchList(params) {
        return http.get("/sourceManagementBatch/getSourceManagementBatchList",{params})
    },
    getSourceManagementBatchByBatchCode(params) {
        return http.get("/sourceManagementBatch/getSourceManagementBatchByBatchCode",{params})
    },
    getSourceManagementEnvironmentData(params) {
        return http.get("/sourceManagementBatch/getSourceManagementEnvironmentData",{params})
    },
    deleteSourceManagementBatch(params) {
        return http.get("/sourceManagementBatch/deleteSourceManagementBatch", {params})
    },
    createSourceManagementBatch(data) {
        return http.post("/sourceManagementBatch/createSourceManagementBatch", data)
    },
    updateSourceManagementBatch(data) {
        return http.post("/sourceManagementBatch/updateSourceManagementBatch", data)
    },

    /*配送管理*/
    deleteSourceDistribution(params) {
        return http.get("/sourceDistribution/deleteSourceDistribution", {params})
    },
    createSourceDistribution(data) {
        return http.post("/sourceDistribution/createSourceDistribution", data)
    },
    updateSourceDistribution(data) {
        return http.post("/sourceDistribution/updateSourceDistribution", data)
    },
    getSourceDistributionList(params) {
        return http.get("/sourceDistribution/getSourceDistributionList", {params})
    },
    getSourceDistributionListPage(params) {
        return http.get("/sourceDistribution/getSourceDistributionListPage", {params})
    },

    /*溯源证书*/
    deleteSourceCertificate(params) {
        return http.get("/sourceCertificate/deleteSourceCertificate", {params})
    },
    createSourceCertificate(data) {
        return http.post("/sourceCertificate/createSourceCertificate", data)
    },
    updateSourceCertificate(data) {
        return http.post("/sourceCertificate/updateSourceCertificate", data)
    },
    getSourceCertificateList(params) {
        return http.get("/sourceCertificate/getSourceCertificateList", {params})
    },
    getSourceCertificateListPage(params) {
        return http.get("/sourceCertificate/getSourceCertificateListPage", {params})
    },

    /*溯源检测管理*/
    deleteSourceDetection(params) {
        return http.get("/sourceDetection/deleteSourceDetection", {params})
    },
    createSourceDetection(data) {
        return http.post("/sourceDetection/createSourceDetection", data)
    },
    updateSourceDetection(data) {
        return http.post("/sourceDetection/updateSourceDetection", data)
    },
    getSourceDetectionList(params) {
        return http.get("/sourceDetection/getSourceDetectionList", {params})
    },
    getSourceDetectionListPage(params) {
        return http.get("/sourceDetection/getSourceDetectionListPage", {params})
    },

    /*溯源销售管理*/
    deleteSourceMarket(params) {
        return http.get("/sourceMarket/deleteSourceMarket", {params})
    },
    createSourceMarket(data) {
        return http.post("/sourceMarket/createSourceMarket", data)
    },
    updateSourceMarket(data) {
        return http.post("/sourceMarket/updateSourceMarket", data)
    },
    getSourceMarketList(params) {
        return http.get("/sourceMarket/getSourceMarketList", {params})
    },
    getSourceMarketListPage(params) {
        return http.get("/sourceMarket/getSourceMarketListPage", {params})
    },

    /*溯源加工管理*/
    deleteSourceProcess(params) {
        return http.get("/sourceProcess/deleteSourceProcess", {params})
    },
    createSourceProcess(data) {
        return http.post("/sourceProcess/createSourceProcess", data)
    },
    updateSourceProcess(data) {
        return http.post("/sourceProcess/updateSourceProcess", data)
    },
    getSourceProcessList(params) {
        return http.get("/sourceProcess/getSourceProcessList", {params})
    },
    getSourceProcessListPage(params) {
        return http.get("/sourceProcess/getSourceProcessListPage", {params})
    },


};

export default global
