<template>
  <div class="chart">
    <dv-loading v-if="loading"
      ><span class="loadingtxt">Loading...</span></dv-loading
    >
    <div class="capsule" v-else ref="boxct">
      <vue-seamless-scroll
        :data="chartData"
        :class-option="defaultOption"
        class="scroll-box"
        v-if="update"
      >
        <div class="c-item" v-for="(item, index) in chartData" :key="index" ref="itemct">
          <div class="label">{{ item.collectItem }}</div>
          <div class="progress">
            <div
              class="suc"
              :style="{ background: item.color, width: item.percentStr }"
            ></div>
            <div class="suc-txt">{{ item.percentStr }}</div>
          </div>
          <!-- <div class="info">{{ item.percentStr }}</div> -->
        </div>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
// 滚动模块
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  name: "cropTypesChart",
  components: {
    vueSeamlessScroll,
  },
  computed: {
    defaultOption() {
      return {
        step: 0.1, // 数值越大速度滚动越快
        limitMoveNum: 7, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  data() {
    return {
      loading: true,
      chart: null,
      update: true,
      chartData: [],
      chartDataList: [],
      // 图表颜色列表
      colorList: [
        "#9966ff",
        "#6666ff",
        "#6699ff",
        "#66ff99",
        "#ffff66",
        "#ffcc66",
        "#ff9966",
        "#ff6666",
        "#abcdef",
        "#bedcaf",
        "#cafedb",
        "#decafb",
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    // 获取图表数据
    getData() {
      // 加载
      this.loading = true;
      this.http.dataV
        .getFarmAgriculturalProductsMsg()
        .then((res) => {
          if (res.status == "200") {
            let data = res.data.data;
            this.chartData = data;
            this.initChart();
          } else {
            this.$message.error("获取设备监控数据超时");
          }
        })
        .then(()=>{
          this.getDomHeight();
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    initChart() {
      let clist = this.chartData || []
      let colorlist = this.colorList;
      let ci = 0;
      for (let i = 0; i < clist.length; i++) {
        clist[i].color = colorlist[ci];
        clist[i].percentStr = clist[i].percent + "%";
        if (ci < 11) {
          ci++;
        } else {
          ci = 0;
        }
      }
      this.chartData = clist;
      // console.log(clist)
      this.loading = false;
    },
    getDomHeight(){
      let that = this
      let boxdom = that.$refs.boxct
      let itemdom = that.$refs.itemct
      let listlen = that.chartData.length
      if(listlen == 0){
        return
      }
      if((itemdom[0].clientHeight * listlen) > boxdom.clientHeight){
        that.defaultOption.limitMoveNum = 1
        that.update = false
        setTimeout(() => {
          that.update = true
        }, 100);
      }
    }
  },
};
</script>

<style scoped lang="less">
* {
  box-sizing: border-box;
}
.loadingtxt {
  color: #ffffff;
}

.scroll-box {
  // margin: 20px 0 20px 0;
  overflow: hidden;
}
.chart::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.chart {
  width: 100%;
  height: calc(100% - 60px);
  padding: 0 10px 10px 20px;
  overflow: auto;
  scrollbar-width: none;
  .capsule {
    width: 100%;
    height: 100%;
    .c-item {
      display: flex;
      align-items: center;
      color: #ffffff;
      margin-bottom: 4px;
      .label {
        width: 15%;
      }
      .progress {
        flex: 1;
        height: 10px;
        display: flex;
        align-items: center;
        .suc {
          height: 8px;
          border-radius: 2px;
        }
        .suc-txt {
          margin-left: 4px;
        }
      }
      .info {
        width: 15%;
      }
    }
  }
}
</style>


