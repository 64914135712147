import { render, staticRenderFns } from "./warningMsgTemp.vue?vue&type=template&id=84e609ee&scoped=true&"
import script from "./warningMsgTemp.vue?vue&type=script&lang=js&"
export * from "./warningMsgTemp.vue?vue&type=script&lang=js&"
import style0 from "./warningMsgTemp.vue?vue&type=style&index=0&id=84e609ee&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84e609ee",
  null
  
)

export default component.exports