import http from '../axios/http'

const api = {
    getRelayControlPointList(params) {
        return http.get("/relay/getRelayControlPointList", {params})
    },

    operationRelayControlPoint(data) {
        return http.post("/relay/operationRelayControlPoint", data)
    },

    judgeRelayControlPoint(data) {
        return http.post("/relay/judgeRelayControlPoint", data)
    },

    updateRelayControlPoint(data) {
        return http.post("/relay/updateRelayControlPoint", data)
    },


    getRelayControlPointBaseList(params) {
        return http.get("/relay/getRelayControlPointBaseList", {params})
    },
    deleteRelayControlPoint(params) {
        return http.get("/relay/deleteRelayControlPoint", {params})
    },
    createRelayControlPoint(data) {
        return http.post("/relay/createRelayControlPoint", data)
    },

    getRelayModelList(params) {
        return http.get("/relayModel/getRelayModelList", {params})
    },
    deleteRelayModel(params) {
        return http.get("/relayModel/deleteRelayModel", {params})
    },
    createRelayModel(data) {
        return http.post("/relayModel/createRelayModel", data)
    },
    updateRelayModel(data) {
        return http.post("/relayModel/updateRelayModel", data)
    },


};

export default api
