import http from '../axios/http'

const global = {
    getUserMsg() {
        return http.get("/user/getLoginUserMsg")
    },
    getWebSetting() {
        let host = location.host;
        return http.get("/webSetting/getPageSettingByUrl",{params: {url: host}})
            .then(response => {
                if (response.data.status === 200) {
                    let webSetting = response.data.data;
                    window.localStorage.setItem("web_setting", JSON.stringify(webSetting));
                }})
    },
    logout(params) {
        return http.get("/web/authority/logout", {params})
    },
    login(data) {
        return http.post("/web/authority/login", data)
    },
};

export default global
