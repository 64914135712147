import http from '../axios/http'

const global = {
    getOrganizationMeasurePointTree(params) {
        return http.get("/monitor/getOrganizationMeasurePointTree", {params})
    },
    getOnlineMonitoringData(params) {
        return http.get("/monitor/getOnlineMonitoringData", {params})
    },
    getOnlineMonitoringWarningData(params) {
        return http.get("/monitor/getOnlineMonitoringWarningData", {params})
    },
    getRealTimeData(params) {
        return http.get("/monitor/getRealTimeData", {params})
    },

};

export default global
