import Vue from 'vue'
import authVerify from '../methods/methods'
/** 权限指令**/
const permission = Vue.directive('auth', {
    inserted: function (el, binding) {
        let permission = binding.value; // 获取到 v-permission的值
        let hasPermission = authVerify.verify(permission);
        if (!hasPermission) {
            // 没有权限 移除Dom元素
            el.parentNode && el.parentNode.removeChild(el)
        }
    }
});

export default permission

