import http from '../axios/http'

const global = {
    getPlantVarietyListPage(params) {
        return http.get("/plantVariety/getPlantVarietyListPage", {params})
    },
    deletePlantVariety(params) {
        return http.get("/plantVariety/deletePlantVariety", {params})
    },
    createdPlantVariety(data) {
        return http.post("/plantVariety/createdPlantVariety", data)
    },
    updatePlantVariety(data) {
        return http.post("/plantVariety/updatePlantVariety", data)
    },
    getPlantVarietyList() {
        return http.get("/plantVariety/getPlantVarietyList")
    },

};

export default global
