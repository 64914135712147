<template>
  <div class="null" :class="background == 'false' ? '' : 'null_background'">
    <div class="img">
      <img src="../../img/empty.png" alt="">
    </div>
    <div class="text">{{tips || '暂无数据'}}</div>
  </div>
</template>
<script>
// 需要在父组件 set position relative
export default {
  name: 'rb-empty',
  props: {
    tips: '',
    imgLink: '',
    background: true
  },
  data() {
    return {
      text: '',
      imgUrl: '',
      status: ''
    };
  },
  mounted(){

  },
  methods: {

  }
};
</script>

<style scoped lang="less">
*{
  box-sizing: border-box;
}
.null_background{
  background: #fff;
}
.null{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 15;
  .text{
    font-size: 14px;
    color: #dbdbdb;
  }
  .img{
    height: 40%;
    max-height: 120px;
    // max-width: 250px;
    margin-bottom: 8px;
    img{
      // width: 100%;
      // height: auto;
      height: 100%;
      width: auto;
    }
  }
}
</style>