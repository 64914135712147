const global = {
    verify(permission) {
        if (permission) {
            let permissionList = [];
            if(permission.indexOf(",") > -1){
                permissionList = permission.split(",");
            }else{
                permissionList.push(permission);
            }
            let hasPermission = checkArray(permissionList);
            return hasPermission;
        }
        return false;
    },
};

function checkArray(keys) {
    const arrayStr = localStorage.getItem('page_authority');
    if (arrayStr === undefined || arrayStr === null) {
        return false
    }
    const array = JSON.parse(arrayStr);
    let hasPermission = false;
    for (let key of keys) {
        let index = array.indexOf(key);
        if(index > -1) {
            hasPermission = true
        }
    }
    return hasPermission;
}

export default global
