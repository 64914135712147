import http from '../axios/http'

const welcome = {
    /**
     * 获取当前种植农产品
     * @returns {Promise<AxiosResponse<T>>}
     */
    getFarmAgriculturalProductsMsg() {
        return http.get("homePage/getFarmAgriculturalProductsMsg")
    },
    /**
     * 获取基地设备情况
     * @returns {Promise<AxiosResponse<T>>}
     */
    getFarmDeviceMsg() {
        return http.get("homePage/getFarmDeviceMsg")
    },
    /**
     * 获取基地环境数据
     * @returns {Promise<AxiosResponse<T>>}
     */
    getFarmEnvironmentMsg() {
        return http.get("homePage/getFarmEnvironmentMsg")
    },
    /**
     * 获取基地消息
     * @returns {Promise<AxiosResponse<T>>}
     */
    getFarmMsg() {
        return http.get("homePage/getFarmMsg")
    },
    /**
     * 获取预警数据
     * @returns {Promise<AxiosResponse<T>>}
     */
    getWarningMsg() {
        return http.get("homePage/getWarningMsg")
    },
    /**
     * 获取我的农场数据
     * @returns {Promise<AxiosResponse<T>>}
     */
    getFarmParcel() {
        return http.get("homePage/getFarmParcel")
    },
};

export default welcome
