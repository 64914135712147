<template>
  <div class="chart">
    <dv-loading v-if="loading"
      ><span class="loadingtxt">Loading...</span></dv-loading
    >
    <div class="farm" v-else>
      <div class="left">
        <div class="item" v-for="(item, index) in chartDataLeft" :key="index" :style="{'background':item.color}">
          <span v-if="item.monitoringItem!='空'">{{ item.monitoringItem }}：{{item.value + item.unit}}</span>
        </div>
      </div>
      <div class="right">
        <div class="item" v-for="(item, index) in chartDataRight" :key="index" :style="{'background':item.color,'opacity': item.monitoringItem == '空' ? 0 : 1}">
          <span v-if="item.monitoringItem!='空'">{{ item.monitoringItem }}：{{item.value + item.unit}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "farmEnvironment",
  data() {
    return {
      loading: true,
      chart: null,
      chartData: [],
      chartDataRight: [],
      chartDataLeft: [],
      chartDataList: [],
      // 图表颜色列表
      colorList: [
        "#9966ff",
        "#6666ff",
        "#6699ff",
        "#6699ff",
        "#6699ff",
        "#6699ff",
        "#6699ff",
        "#6699ff",
        "#6699ff",
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    // 获取图表数据
    getData() {
      // 加载
      this.loading = true;
      this.http.dataV
        .getFarmEnvironmentMsg()
        .then((res) => {
          if (res.status == "200") {
            let data = res.data.data;
            this.chartData = data;
            this.initChart();
          } else {
            this.$message.error("获取设备监控数据超时");
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    initChart() {
      let clist = this.chartData;
      let llist = []
      let rlist = []
      clist.forEach((val, index) => {
        if ((index + 1) % 2 == 0) {
          val.in = "right";
          rlist.push(val)
        } else {
          val.in = "left";
          llist.push(val)
        }
      });
      llist.forEach((val,index)=>{
        // val.color = this.colorList[index]
        llist[index].color = this.colorList[index]
        rlist[index] ? rlist[index].color = this.colorList[index] : rlist[index] = {monitoringItem:'空',color:this.colorList[index]}
      })
      // llist.forEach((val,index)=>{
      //   val.color = this.colorList[index]
      // })
      this.chartDataRight = rlist;
      this.chartDataLeft = llist;
      this.chartData = clist;
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="less">
* {
  box-sizing: border-box;
}
.loadingtxt {
  color: #ffffff;
}
.chart {
  width: 100%;
  height: calc(100% - 50px);
  padding: 0 20px 0 20px;
  .farm {
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 10px;
    .left,.right{
      width: 48%;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      .item:not(:last-child){
        margin-bottom: 4px;
      }
      .item{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20%;
        // padding: 1em 0;
        border-radius: 4px;
      }
    }
  }
}
</style>


