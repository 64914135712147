<template>
  <div class="show-loading" :showLoading="showLoading">
    <a-spin
        style="position: absolute"
        class="show-loading-txt"
        size="large"
        tip="努力加载中..."
    />
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    showLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.show-loading {
  width: 100%;
  height: 100%;
  background-color: rgba(230, 247, 255, .2);
}

.show-loading-txt {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
