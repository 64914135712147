import http from '../axios/http'

const api = {
    getWebSetting(){return http.get("/pageSetting/getWebSetting", {})},
    getAllWebSetting(){return http.get("/pageSetting/getAllWebSetting", {})},
    deleteWebSetting(id){return http.get("/pageSetting/deleteWebSetting", {params:{id:id}})},
    createOrUpdateWebSetting(obj){return http.post("/pageSetting/createOrUpdateWebSetting", obj)},
};

export default api
