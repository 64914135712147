import http from '../axios/http'

const api = {
    getMachineryList() {
        return http.get("machinery/netty/getMachineryList")
    },

    machineryOperation(params) {
        return http.get("machinery/netty/machineryOperation", {params})
    },

    machinerySelectPlot(params) {
        return http.get("machinery/netty/machinerySelectPlot", {params})
    },


    pickStart(params) {
        return http.get("pick/netty/pickStart", {params})
    },
    pickStop(params) {
        return http.get("pick/netty/pickStop", {params})
    },
    getPickList(params) {
        return http.get("pick/netty/getPickList", {params})
    },


};

export default api
