import http from '../axios/http'

const global = {
    deleteDevice(params) {
        return http.get("/equipment/deleteDevice", {params})
    },
    deleteDeviceClassify(params) {
        return http.get("/equipment/deleteDeviceClassify", {params})
    },
    getDeviceClassifyTree(params) {
        return http.get("/equipment/getDeviceClassifyTree", {params})
    },
    getDeviceClassifyList(params) {
        return http.get("/equipment/getDeviceClassifyList", {params})
    },
    createDeviceClassify(data) {
        return http.post("/equipment/createDeviceClassify", data)
    },
    updateDeviceClassify(data) {
        return http.post("/equipment/updateDeviceClassify", data)
    },
    createDevice(data) {
        return http.post("/equipment/createDevice", data)
    },
    updateDevice(data) {
        return http.post("/equipment/updateDevice", data)
    },
    getDeviceListPage(params) {
        return http.get("/equipment/getDeviceListPage", {params})
    },
};

export default global
