import http from '../axios/http'

const api = {
    getEnvironmentMeterCollectItem(params) {
        return http.get("/report/getEnvironmentMeterCollectItem", {params})
    },
    getEnvironmentReport(params) {
        return http.get("/report/getEnvironmentReport", {params})
    },
    getCropManagementReport(params) {
        return http.get("/report/getCropManagementReport", {params})
    },
    getAssetWarehouseReport(params) {
        return http.get("/report/getAssetWarehouseReport", {params})
    },
};

export default api