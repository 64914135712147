import http from '../axios/http'

const global = {
    getAreaListHasId(params) {
        return http.get("/area/getAreaListHasId", {params})
    },

};

export default global
