import http from '../axios/http'

const global = {
    getCropManagementListPage(params) {
        return http.get("/cropManagement/getCropManagementListPage", {params})
    },
    deleteCropManagement(params) {
        return http.get("/cropManagement/deleteCropManagement", {params})
    },
    createdCropManagement(data) {
        return http.post("/cropManagement/createdCropManagement", data)
    },
    updateCropManagement(data) {
        return http.post("/cropManagement/updateCropManagement", data)
    },
    updateCropManagementState(data) {
        return http.post("/cropManagement/updateCropManagementState", data)
    },
    getCropManagementList(params) {
        return http.get("/cropManagement/getCropManagementList",{params})
    },
    getCropManagementLogList(params) {
        return http.get("/cropManagement/getCropManagementLogList",{params})
    },

};

export default global
