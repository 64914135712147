import router from '../router'
import request from "axios";
import qs from "qs";
import {Message} from "element-ui";
import prefix from '../axios/base';

let instance = request.create({ timeout: 1000 * 30 });
instance.defaults.baseURL=prefix.SERVER;
//instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
/**
 * 请求拦截器
 */
instance.interceptors.request.use(config => {
    //console.log("提交转换前",config);
    let token = window.localStorage.getItem('access_token');
    let organizationId = window.localStorage.getItem('organizationId');
    if(token!=null && token!=""){
        config.headers.Authorization  =token;
    }
    if(organizationId!=null && organizationId!=""){
        config.headers.organizationId  = organizationId;
    }
    config.data = qs.stringify(config.data);
    return config
},err => {
    Message.error({message: '请求超时！'});
    return Promise.reject(err);
});

/**
 * 响应拦截器
 */
instance.interceptors.response.use(response => {
    return response;
},error => {
    if (error.response) {
        if (error.response.status === 401) {
            localStorage.removeItem('access_token');
            router.push({path: '/login'});
        }
        // 返回接口返回的错误信息
        return Promise.reject(error.response);
    }
});

export default instance
