<template>
  <div class="chart">
    <div class="state">
       <div class="item" v-for="(item,index) in stateData" :key="index">
           <div class="label">{{item.item}}</div>
           <div class="info">{{item.value}}</div>
       </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "farmState",
  data() {
    return {
      stateData: []
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    // 获取图表数据
    getData() {
      // 加载
      this.loading = true;
      this.http.dataV
        .getFarmStatisticsData()
        .then((res) => {
          if (res.status == "200") {
            let data = res.data.data;
            this.stateData = data;
            // // console.log(data)
          } else {
            this.$message.error("获取设备监控数据超时");
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    formatter(number) {
      const numbers = number.toString().split("").reverse();
      const segs = [];
      while (numbers.length) segs.push(numbers.splice(0, 3).join(""));
      return segs.join(",").split("").reverse().join("");
    },
  },
};
</script>

<style scoped lang="less">
* {
  box-sizing: border-box;
}
.loadingtxt {
  color: #ffffff;
}
.chart {
  width: 100%;
  height: 100%;
  padding: 0 40px 5px 40px;
  .state {
    width: 100%;
    height: 100%;
    background: rgba(5, 64, 115, 0.5);
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #ffffff;
    .item{
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .label{
            font-size: 18px;
            font-weight: bold;
        }
        .info{
            font-size: 24px;
            background: #ff7a00;
            width: 70%;
            text-align: center;
            border-radius: 4px;
            font-weight: bold;
        }
    }
  }
}
</style>


