import http from '../axios/http'

const global = {
    getUserListByOrganizationId(params) {
        return http.get("/user/getUserListByOrganizationId", {params})
    },
    updateOneselfUser(data) {
        return http.post("/user/updateOneselfUser", data)
    },
    getRoleList(params) {
        return http.get("/user/getRoleList", {params})
    },
    getRoleListPage(params) {
        return http.get("/user/getRoleListPage", {params})
    },
    createRole(data) {
        return http.post("/user/createRole", data)
    },
    updateRole(data) {
        return http.post("/user/updateRole", data)
    },
    deleteRole(params) {
        return http.get("/user/deleteRole", {params})
    },
    createUser(data) {
        return http.post("/user/createUser", data)
    },
    getUserList(params) {
        return http.get("/user/getUserList", {params})
    },
    updateUser(data) {
        return http.post("/user/updateUser", data)
    },
    deleteUser(params) {
        return http.get("/user/deleteUser", {params})
    },
};

export default global
