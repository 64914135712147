<template>
  <div class="chart">
    <dv-loading v-if="loading"
      ><span class="loadingtxt">Loading...</span></dv-loading
    >
    <div class="map" v-else>

          <div style="position: relative;margin-right: 0px;margin-left: 0px;height: 100%;z-index: 98">

      <div id="mapCard"></div>
          </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "@antv/g2";
import { Mapbox } from "@antv/l7-maps";
import { Scene, PointLayer, Marker } from "@antv/l7";
import { DrawPolygon } from "@antv/l7-draw";
import { GaodeMapV2 } from "@antv/l7-maps";
export default {
  name: "mapChart",
  data() {
    return {
      loading: true,
      chart: null,
      scene: null,
      chartData: [],
      farmParcelMap: {
          lng: '',
          lat: '',
          ponits: [],
          areaMarkers: [],
      },
      chartDataList: [],
      // 图表颜色列表
      colorList: [
        "#9966ff",
        "#6666ff",
        "#6699ff",
        "#6699ff",
        "#6699ff",
        "#6699ff",
        "#6699ff",
        "#6699ff",
        "#6699ff",
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    // 获取图表数据
    getData() {
      // 加载
      this.loading = true;
      this.http.welcome.getFarmParcel().then(res => {
          if (res.status == "200") {
            let data = res.data.data;
            this.farmParcelMap.lng = data.lng;
            this.farmParcelMap.lat = data.lat;
            this.farmParcelMap.ponits = data.ponits;
            this.farmParcelMap.areaMarkers = data.areaMarkers;
            this.chartData = data;
            this.initChart();
          } else {
            this.$message.error("获取设备监控数据超时");
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    initChart() {
        let that = this
          that.loading = false;
        that.$nextTick(() => {
          let scene = that.scene;
          let areaMarkers = that.farmParcelMap.areaMarkers;
          let lng = that.farmParcelMap.lng;
          let lat = that.farmParcelMap.lat;
          let ponits = that.farmParcelMap.ponits;
          const style = {
            normal: {
              polygon: {
                type: "PolygonLayer",
                shape: "fill",
                color: "#1a83ff",
                style: {
                  opacity: 0.1,
                  stroke: "#2fa6ff",
                  strokeWidth: 1,
                  strokeOpacity: 1,
                  lineType: "solid",
                  dashArray: [2, 2],
                },
              },
            },
          };
          const polygon = {
            type: "FeatureCollection",
            features: [],
          };
          for (let ponit of ponits) {
            polygon.features.push({
              type: "Feature",
              properties: {},
              geometry: {
                type: "Polygon",
                coordinates: JSON.parse(ponit),
              },
            });
          }
          scene = new Scene({
            id: "mapCard",
            logoVisible: false,
            map: new Mapbox({
              style: "mapbox://styles/mapbox/satellite-streets-v11",
              center: [lng, lat],
              pitch: 10,
              zoom: 14.95,
              rotation: 19.313180925794313,
              token:
                "pk.eyJ1IjoiZGlhbmF5IiwiYSI6ImNrdDNvazJpdTB2ejgydXVsdW50OWgyMTMifQ.Qax_apbCk2-XhMlwmzB6kA",
            }),
          });
          scene.on("loaded", () => {
            createDraw();
            addMarkers();
          });
          that.scene = scene;
          function createDraw() {
            new DrawPolygon(scene, {
              data: polygon,
              selectEnable: false,
              style: style,
            });
          }
          function addMarkers() {
            for (let point of areaMarkers) {
              const el = document.createElement("label");
              el.className = "labelclass";
              el.textContent = point.name;
              const marker = new Marker({
                element: el,
              }).setLnglat({ lng: point.lng * 1, lat: point.lat });
              scene.addMarker(marker);
            }
          }
        });
    },
  },
};
</script>
<style scoped>
#mapCard >>> .labelclass {
    padding: 0.05rem;
    color: #FFFFFF;
    border-radius: 5px;
    background-color: #ffab26;
  }
</style>
<style scoped lang="less">
* {
  box-sizing: border-box;
}
.loadingtxt {
  color: #ffffff;
}
.chart {
  width: 100%;
  height: 100%;
  padding: 10px;
  .map {
    width: 100%;
    height: 100%;
  }
}
</style>


