<template>
  <div class="view">
    <div class="head">
      <div class="head-bgimg"></div>
      <div class="logo">
        <div class="logo-img">
          <img :src="viewData.logoImgPathAll" alt="" />
        </div>
        <div class="logo-txt">
          <div class="label">{{ viewData.logoDescribe }}</div>
          <div class="time">{{ date }}</div>
        </div>
      </div>
      <div class="title">{{ viewData.title }}</div>
      <div class="setting">
        <div class="set-item">
          <!-- <img src="../../img/test/weather_sun.png" alt="" /> -->
          <span v-if="weatherInfo">今天天气 {{ weatherInfo.weather }}</span>
        </div>
        <!-- <div class="set-item">设置</div> -->
        <div class="set-item man-btn-sty" @click="screen">全屏</div>
        <div class="set-item man-btn-sty" @click="toManagerTab">管理端</div>
      </div>
    </div>

    <div class="main">
      <div class="left">
        <!-- <dv-border-box-8>dv-border-box-8</dv-border-box-8> -->
        <dv-border-box-13 class="view-item">
          <!-- 设备监控 -->
          <div class="title">设备监控</div>
          <FarmDeviceTemp v-if="farmDeviceTempFlag" />
          <dv-loading v-else
            ><span class="loadingtxt">Loading...</span></dv-loading
          >
          <!-- <div class="bottom"></div> -->
        </dv-border-box-13>
        <dv-border-box-13 class="view-item">
          <!-- 农事任务 -->
          <div class="title">农事任务统计</div>
          <InsectChartTemp v-if="insectChartTempFlag" />
          <dv-loading v-else
            ><span class="loadingtxt">Loading...</span></dv-loading
          >
        </dv-border-box-13>
        <dv-border-box-13 class="view-item">
          <!-- 墒情监控 -->
          <div class="title">墒情监控</div>
          <SoilChartTemp v-if="soilChartTempFlag" />
          <dv-loading v-else
            ><span class="loadingtxt">Loading...</span></dv-loading
          >
        </dv-border-box-13>
      </div>
      <div class="center">
        <dv-border-Box-10 class="view-map-item">
          <div class="center-info">
            <!-- <div class="ci-item"></div> -->
            <!-- 浮动total -->
            <FarmStateTemp v-if="farmStateTempFlag" />
          </div>
          <!-- center地图组件 -->
          <MapChartTemp v-if="mapChartTempFlag" />
          <dv-loading v-else
            ><span class="loadingtxt">Loading...</span></dv-loading
          >
        </dv-border-Box-10>
        <dv-border-box-6 class="view-video-item">
          <!-- 视频监控模块 -->
          <CameraTemp v-if="cameraTempFlag" />
          <dv-loading v-else
            ><span class="loadingtxt">Loading...</span></dv-loading
          >
        </dv-border-box-6>
        <div class="view-item-box">
          <dv-border-box-13 class="vi-item">
            <!-- 农作物种类占比 -->
            <div class="title">农作物种类占比</div>
            <CropTypesChartTemp v-if="cropTypesChartTempFlag" />
            <dv-loading v-else
              ><span class="loadingtxt">Loading...</span></dv-loading
            >
          </dv-border-box-13>
          <dv-border-box-13 class="vi-item">
            <!-- 农作物生长周期 -->
            <div class="title">农作物生长状态</div>
            <CropCycleChartTemp v-if="cropCycleChartTempFlag" />
            <dv-loading v-else
              ><span class="loadingtxt">Loading...</span></dv-loading
            >
          </dv-border-box-13>
        </div>
      </div>
      <div class="right">
        <dv-border-box-13 class="view-item">
          <!-- 实时环境监测 -->
          <div class="title">实时环境监测</div>
          <FarmEnvironmentTemp v-if="farmEnvironmentTempFlag" />
          <dv-loading v-else
            ><span class="loadingtxt">Loading...</span></dv-loading
          >
        </dv-border-box-13>
        <dv-border-box-13 class="view-item">
          <!-- 农机类型统计 -->
          <div class="title">农机类型统计</div>
          <FarmMachinery v-if="farmMachineryFlag" />
          <dv-loading v-else
            ><span class="loadingtxt">Loading...</span></dv-loading
          >
        </dv-border-box-13>
        <dv-border-box-13 class="view-item">
          <!-- 在线预警 -->
          <div class="title">在线预警</div>
          <WarningMsgTemp v-if="warningMsgTempFlag" />
          <dv-loading v-else
            ><span class="loadingtxt">Loading...</span></dv-loading
          >
        </dv-border-box-13>
      </div>
    </div>
  </div>
</template>

<script>
  //引入全屏组件
  import screenfull from 'screenfull'
// 农事任务模块
import InsectChartTemp from "./insectChartTemp";
// 墒情监控模块
import SoilChartTemp from "./soilChartTemp";
// 农作物种类监控模块
import CropTypesChartTemp from "./cropTypesChartTemp";
// 农作物周期监控模块
import CropCycleChartTemp from "./cropCycleChartTemp";
// 实时环境监控
import FarmEnvironmentTemp from "./farmEnvironmentTemp";
// 在线预警
import WarningMsgTemp from "./warningMsgTemp";
// 地图图表
import MapChartTemp from "./mapChartTemp";
// 农机状态分类统计
import FarmMachinery from "./farmMachinery";
import FarmStateTemp from "./farmStateTemp";
import CameraTemp from "./cameraTemp";
import FarmDeviceTemp from "./farmDeviceTemp";
export default {
  name: "DataV",
  components: {
    SoilChartTemp,
    CropTypesChartTemp,
    CropCycleChartTemp,
    FarmEnvironmentTemp,
    WarningMsgTemp,
    MapChartTemp,
    FarmMachinery,
    FarmStateTemp,
    CameraTemp,
    InsectChartTemp,
    FarmDeviceTemp,
  },
  computed: {},
  data() {
    return {
      // 图表颜色列表
      colorList: [
        "#9966ff",
        "#6666ff",
        "#6699ff",
        "#66ff99",
        "#ffff66",
        "#ffcc66",
        "#ff9966",
        "#ff6666",
        "#abcdef",
        "#bedcaf",
        "#cafedb",
        "#decafb",
      ],
      // 监控浏览器窗口变化
      screenWidth: document.body.clientWidth,
      timer: false,
      date: null,
      farmDeviceTempFlag: false,
      insectChartTempFlag: false,
      soilChartTempFlag: false,

      farmStateTempFlag: false,
      mapChartTempFlag: false,
      cameraTempFlag: false,
      cropTypesChartTempFlag: false,
      cropCycleChartTempFlag: false,

      farmEnvironmentTempFlag: false,
      farmMachineryFlag: false,
      warningMsgTempFlag: false,
      viewData: {},
      weatherInfo: {},
      inter: null
    };
  },
  created() {
      if(!screenfull.isFullscreen){
          screenfull.toggle();
      }
  },
    destroyed() {
      console.log("离开");
        if(screenfull.isFullscreen){
            screenfull.toggle();
        }
        clearInterval(this.inter)
    },
  mounted() {
    let that = this;
    // 添加改变窗口大小改变监听
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
    // 获取页面数据
    that.getViewData();
    // 初次加载视图
    that.initChart()
    // 获取当日天气
    that.getWeatherInfo()
    that.inter = setInterval(() => {
      that.uploadTemp2();
    },(1000 * 60 * 10));
  },
  watch: {
    screenWidth(val) {
      let that = this;
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!that.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        that.screenWidth = val;
        that.timer = true;
        setTimeout(function () {
          that.uploadTemp();
          that.timer = false;
        }, 500);
      }
    },
  },

  methods: {
      screen() {
          screenfull.toggle();
      },
    getViewData() {
      let that = this;
      // 获取当前页面信息
      that.date = setInterval(() => {
        let year = new Date().getFullYear();
        let month =new Date().getMonth() + 1 < 10? "0" + (new Date().getMonth() + 1): new Date().getMonth() + 1;
        let date =new Date().getDate() < 10? "0" + new Date().getDate(): new Date().getDate();
        let hh =new Date().getHours() < 10? "0" + new Date().getHours(): new Date().getHours();
        let mm =new Date().getMinutes() < 10? "0" + new Date().getMinutes(): new Date().getMinutes();
        let ss =new Date().getSeconds() < 10? "0" + new Date().getSeconds(): new Date().getSeconds();
        that.date = year + "年" + month + "月" + date +"日"+" "+hh+":"+mm+':'+ss ;
      }, 1000);
      that.http.dataV
        .getTopPortionInfo()
        .then((res) => {
          if (res.data.status == "200") {
            let data = res.data.data;
            that.viewData = data;
          } else {
            that.$message.error("获取界面数据超时");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getWeatherInfo(){
      let that = this;
      that.http.dataV
        .getWeatherInfo()
        .then((res) => {
          if (res.data.status == "200") {
            let data = res.data.data
            that.weatherInfo = data
          } else {
            that.$message.error("获取天气数据超时");
          }
        })
        .catch((err) => {
          that.$message.error("获取天气数据超时");
          console.log(err);
        });
    },
    uploadTemp() {
      let that = this;
      setTimeout(()=>{
        that.cropTypesChartTempFlag = false;
        that.cropCycleChartTempFlag = false;
        that.farmDeviceTempFlag = false;
        that.$nextTick(() => {
        that.cropTypesChartTempFlag = true;
        that.cropCycleChartTempFlag = true;
          that.farmDeviceTempFlag = true;
        });
        setTimeout(() => {
          that.insectChartTempFlag = false;
          that.$nextTick(() => {
            that.insectChartTempFlag = true;
          });
        }, 200);
        setTimeout(() => {
          that.soilChartTempFlag = false;
          that.$nextTick(() => {
            that.soilChartTempFlag = true;
          });
        }, 300);
        setTimeout(() => {
          that.cameraTempFlag = false;
          that.$nextTick(() => {
            that.cameraTempFlag = true;
          });
        }, 500);
      },100)
    },
    uploadTemp2() {
      console.log('更新')
      let that = this;
        that.cropTypesChartTempFlag = false
        that.insectChartTempFlag = false
        that.farmMachineryFlag = false
        that.warningMsgTempFlag = false
      that.$nextTick(() => {
          that.cropTypesChartTempFlag = true
          that.insectChartTempFlag = true
          that.farmMachineryFlag = true
          that.warningMsgTempFlag = true
      });
        setTimeout(function () {
            that.farmDeviceTempFlag = false
            that.$nextTick(() => {
                that.farmDeviceTempFlag = true
            });
        },10000)
      setTimeout(function () {
          that.farmEnvironmentTempFlag = false
        that.$nextTick(() => {
          that.farmEnvironmentTempFlag = true
        });
      },20000)
    },
    initChart(){
      let that = this
      that.farmStateTempFlag = true;
      that.farmDeviceTempFlag = true;
      that.farmEnvironmentTempFlag = true;
      setTimeout(()=>{
      that.farmMachineryFlag = true;
      that.insectChartTempFlag = true;
      that.cropTypesChartTempFlag = true;
      that.cropCycleChartTempFlag = true;
      },500)
      setTimeout(()=>{
      that.soilChartTempFlag = true;
      that.warningMsgTempFlag = true;
      },1000)
      setTimeout(()=>{
      that.mapChartTempFlag = true;
      },1500)
      setTimeout(()=>{
      that.cameraTempFlag = true;
      },2500)


    },
  toManagerTab(){
    this.$router.push('/home')
  }
  },
  beforeDestroy() {
    if (this.date) {
      clearInterval(this.date); // 在Vue实例销毁前，清除我们的定时器
    }
  }
};
</script>
<style scoped>
.dv-loading {
  position: absolute;
  top: 0;
}
</style>
<style scoped lang="less">
div {
  box-sizing: border-box;
}
.scroll-box {
  margin: 20px 0 20px 0;
  overflow: hidden;
}
.flex-row-center {
  display: flex;
  align-items: center;
}
.view::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.view {
  background: #054073;
  width: 100%;
  height: 100%;
  min-width: 1024px;
  min-height: 600px;
  scrollbar-width: none;
  overflow-y: auto;
  .head {
    height: 70px;
    .flex-row-center();
    color: #fff;
    z-index: 110;
    position: relative;
    .logo {
      width: 25%;
      .flex-row-center();
      .logo-img {
        width: 60px;
        height: 60px;
        img {
          width: 100%;
          height: auto;
        }
      }
      .logo-txt {
        font-size: 16px;
        position: relative;
        width: calc(100% - 60px);
        margin-bottom: 15px;
        .label {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 100%;
        }
        .time {
          position: absolute;
          left: 0;
          bottom: -16px;
          font-size: 14px;
        }
      }
    }
    .title {
      width: 50%;
      font-size: 34px;
      .flex-row-center();
      justify-content: center;
      margin-top: 15px;
    }
    .setting {
      width: 25%;
      font-size: 14px;
      .flex-row-center();
      justify-content: flex-end;
      .man-btn-sty {
        background: #409eff;
        padding: 4px 14px;
        border-radius: 4px;
      }
      .set-item {
        margin-right: 14px;
        .flex-row-center();
        cursor: pointer;
        img {
          width: 40px;
          height: auto;
          margin-right: 7px;
        }
      }
    }
  }
  .main {
    height: calc(100% - 70px);
    display: flex;
    justify-content: space-between;
    padding: 0 14px 14px 14px;
    .left {
      width: 25%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .center {
      // margin: 0 24px;
      width: 48%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .view-map-item {
        width: 100%;
        height: 55%;
        margin-bottom: 14px;
        position: relative;
        .center-info {
          position: absolute;
          top: 18px;
          width: 100%;
          height: 90px;
          z-index: 99;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .view-video-item {
        height: 20%;
        margin-bottom: 14px;
      }
      .view-item-box {
        height: 25%;
        display: flex;
        .vi-item {
          width: 50%;
          height: 100%;
          .title {
            padding: 20px 20px 10px 30px;
            height: 50px;
            color: #fff;
            position: relative;
          }
          .title::before {
            position: absolute;
            content: "";
            width: 4px;
            height: 16px;
            background: #00fdff;
            left: 20px;
            top: 22px;
            border-radius: 2px;
          }
        }
      }
    }
    .right {
      width: 25%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // .view-item:not(:last-child) {
      //   margin-bottom: 14px;
      // }
      // .view-item {
      //   height: 33.33%;
      //   width: 100%;
      // }
    }
    .left,
    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // .view-item:not(:last-child) {
      //   margin-bottom: 14px;
      // }
      .view-item {
        height: 33%;
        width: 100%;
        display: flex;
        flex-direction: column;
        .title {
          padding: 20px 20px 10px 30px;
          height: 50px;
          color: #fff;
          position: relative;
        }
        .title::before {
          position: absolute;
          content: "";
          width: 4px;
          height: 16px;
          background: #00fdff;
          left: 20px;
          top: 22px;
          border-radius: 2px;
        }
        .flex-box {
          width: 100%;
          height: calc(100% - 50px);
          .flex-row-center();
        }
      }
    }
  }
}
#chart_pin_eqm {
  width: 100%;
  height: 100%;
}
.loadingtxt {
  color: #fff;
}
.head-bgimg {
  background: url(./title_bg.png) no-repeat;
  background-size: 120% 100%;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  height: 95px;
  width: 100%;
  z-index: -1;
}
</style>
