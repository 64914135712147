<template>
    <div id="app">
        <a-config-provider :locale="zh_CN">
            <router-view></router-view>
        </a-config-provider>
    </div>
</template>

<script>
    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
    import moment from 'moment';
    import 'moment/locale/zh-cn';

    moment.locale('zh-cn');
    export default {
        data() {
            return {
                zh_CN,
            };
        },
        mounted() {
            this.initWebSetting();
        },
        created() {

        },
        methods:{
            initWebSetting(){
                this.http.global.getWebSetting().then(()=>{
                    let web = JSON.parse(window.localStorage.getItem('web_setting'));
                    if(web!=null && web.webTitle){
                        document.title =web.webTitle;
                    }
                });
            }
        }
    }
</script>

<style>

#app::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
#app {
  scrollbar-width: none;
}
  @media (min-width: 1280px) {
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
      padding: 11px 11px !important;
      overflow-wrap: break-word !important;
    }
    .generalTable .ant-table-thead > tr > th,.generalTable .ant-table-tbody > tr > td {
      padding: 0.2rem 5px !important;
      overflow-wrap: break-word !important;
    }
  }
  @media (min-width: 1380px) {
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
      padding: 12px 12px !important;
      overflow-wrap: break-word !important;
    }
    .generalTable .ant-table-thead > tr > th,.generalTable .ant-table-tbody > tr > td {
      padding: 0.3rem 5px !important;
      overflow-wrap: break-word !important;
    }
  }
  @media (min-width: 1480px) {
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
      padding: 13px 13px !important;
      overflow-wrap: break-word !important;
    }
    .generalTable .ant-table-thead > tr > th,.generalTable .ant-table-tbody > tr > td {
      padding: 0.4rem 5px !important;
      overflow-wrap: break-word !important;
    }
  }
  @media (min-width: 1580px) {
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
      padding: 14px 14px !important;
      overflow-wrap: break-word !important;
    }
    .generalTable .ant-table-thead > tr > th,.generalTable .ant-table-tbody > tr > td {
      padding: 0.5rem 5px !important;
      overflow-wrap: break-word !important;
    }
  }
  @media (min-width: 1680px) {
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
      padding: 15px 15px !important;
      overflow-wrap: break-word !important;
    }
    .generalTable .ant-table-thead > tr > th,.generalTable .ant-table-tbody > tr > td {
      padding: 0.6rem 5px !important;
      overflow-wrap: break-word !important;
    }
  }
  @media (min-width: 1790px) {
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
      padding: 16px 16px !important;
      overflow-wrap: break-word !important;
    }
    .generalTable .ant-table-thead > tr > th,.generalTable .ant-table-tbody > tr > td {
      padding: 0.7rem 5px !important;
      overflow-wrap: break-word !important;
    }
  }
  @media (max-width: 1280px) {
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
      padding: 10px 10px !important;
      overflow-wrap: break-word !important;
    }
    .generalTable .ant-table-thead > tr > th,.generalTable .ant-table-tbody > tr > td {
      padding: 0.18rem 5px !important;
      overflow-wrap: break-word !important;
    }
  }
  .my-table-head-style th{
    background: #f5f5f5 !important;
    color: #666 !important;
  }
  .display-center-sty{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
</style>
