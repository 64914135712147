import router from '../router'

import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'

NProgress.configure({
  showSpinner: false ,// 是否显示加载ico
  easing: 'ease',// 动画方式
  speed: 200,// 递增进度条的速度
  trickleSpeed: 200,// 自动递增间隔
  minimum: 0.3// 初始化时的最小百分比
}) // NProgress Configuration


router.beforeEach((to, from, next) => {
  NProgress.start() // start progress bar
  next()
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
