import http from '../axios/http'

const global = {
    getWarningRule(params) {
        return http.get("warningData/getWarningRule", {params})
    },
    getCollectItemRules(params) {
        return http.get("warningData/getCollectItemRules", {params})
    },

    deleteWarningRule(params) {
        return http.get("warningData/deleteWarningRule", {params})
    },
    deleteMeterWarningRule(params) {
        return http.get("warningData/deleteMeterWarningRule", {params})
    },

    createWarningRule(data) {
        return http.post("warningData/createWarningRule", data)
    },
    createMeterWarningRule(data) {
        return http.post("warningData/createMeterWarningRule", data)
    },

    updateWarningRule(data) {
        return http.post("warningData/updateWarningRule", data)
    },
    updateMeterWarningRule(data) {
        return http.post("warningData/updateMeterWarningRule", data)
    },


};

export default global
